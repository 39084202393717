import React from 'react'
import { Route, Switch, useHistory, Redirect } from 'react-router-dom'
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react'
import { ThemeProvider } from '@mui/material'
import { NexusRoutes } from '../apps/fleet-management/FleetRoutes'
import { VehicleDetail } from '../apps/fleet-management/components/vehicle-detail/VehicleDetail'
import NotFound from '../components/404'
import { toRelativeUrl } from '@okta/okta-auth-js'
import { Layout } from '../components/layout'
import ReservationPage from '../apps/fleet-management/components/reservation'
import { oktaAuth } from '../okta/config'
import { CustomerDetailsPage } from '../apps/customer-details/'
import { Promotions } from '../apps/promotions/Promotions'
import theme from '../global-styles'
import { MapContainer } from '../apps/assets-map'
import { Vestaboard } from '../apps/vestaboard/Vestaboard'
import { Transactions } from '../apps/transactions/Transactions'
import { CustomerTable } from '../apps/fleet-management/components/customer/CustomerTable'

const Routes = () => {
  const history = useHistory()

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin))
  }
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <Route path="/login/callback" component={LoginCallback} />
        <Redirect from="/" to="/fleet" exact />
        <SecureRoute path="/fleet">
          <Layout title="Nexus">
            <NexusRoutes />
          </Layout>
        </SecureRoute>
        <SecureRoute path="/view/:from/:id" exact>
          <Layout title="Nexus">
            <ThemeProvider theme={theme}>
              <VehicleDetail />
            </ThemeProvider>
          </Layout>
        </SecureRoute>
        <SecureRoute path="/reservations/:reservationId">
          <Layout title="Reservation" exact>
            <ThemeProvider theme={theme}>
              <ReservationPage />
            </ThemeProvider>
          </Layout>
        </SecureRoute>
        <SecureRoute exact path="/customers">
          <Layout title="Customers Page">
            <CustomerTable />
          </Layout>
        </SecureRoute>
        <SecureRoute exact path="/customers/:customerId">
          <Layout title="Customers Page">
            <CustomerDetailsPage />
          </Layout>
        </SecureRoute>
        <SecureRoute path="/customers/:customerId/invoices">
          <Layout title="Invoices Page">
            <CustomerDetailsPage />
          </Layout>
        </SecureRoute>
        <SecureRoute path="/customers/:customerId/cqe">
          <Layout title="Customer CQE">
            <CustomerDetailsPage />
          </Layout>
        </SecureRoute>

        <SecureRoute path="/customers/:customerId/mileage">
          {/* Mileage is based on subscription not a customer. */}
          <Layout title="Subscription Mileage">
            <CustomerDetailsPage />
          </Layout>
        </SecureRoute>
        <SecureRoute path="/customers/:customerId/documents">
          <Layout title="Documents">
            <CustomerDetailsPage />
          </Layout>
        </SecureRoute>
        <SecureRoute path="/promotions">
          <Layout title="Promotions Landing Page">
            <Promotions />
          </Layout>
        </SecureRoute>
        <SecureRoute path="/transactions">
          <Layout title="All Transactions">
            <Transactions />
          </Layout>
        </SecureRoute>
        <SecureRoute path="/assets/inventory">
          <Layout title="map">
            <MapContainer />
          </Layout>
        </SecureRoute>
        <SecureRoute path="/vestaboard">
          <Layout title="Vestaboard">
            <Vestaboard />
          </Layout>
        </SecureRoute>
        <Route path="*" component={NotFound} />
      </Switch>
    </Security>
  )
}
export default Routes
