import React, { FC, useMemo } from 'react'
import {
  Grid,
  Box,
  Button,
  Modal,
  Select,
  MenuItem,
  Typography,
  TextField,
} from '@mui/material'
import { useChangeProgramDialog } from './useChangeProgramDialog'

import { map } from 'lodash'

import { useChangeProgramDialogStyles } from './ChangeProgramDialog.styles'
import { Program } from '@nxcr-org/web-api-interface/lib/subscription_pb'
import { formatCents } from '../../../../../../utils/currency-formatter'

export type ChangeProgramDialogProps = {
  onClose: () => void
  isOpen: boolean
  currentProgram: Program.AsObject
  customerId: string
  reservationId: string
  modelOptions: object
  trackingId: string
  postal: string
}

export const ChangeProgramDialog: FC<ChangeProgramDialogProps> = ({
  onClose,
  isOpen,
  currentProgram,
  customerId,
  reservationId,
  modelOptions,
  trackingId,
  postal,
}) => {
  const { classes } = useChangeProgramDialogStyles()
  const {
    programs,
    program,
    pricingId,
    setProgram,
    setPricingId,
    saveProgramSelection,
    isLoading,
    programPricingOptions,
  } = useChangeProgramDialog({
    modelOptions,
    currentProgram,
    customerId,
    reservationId,
    onClose,
    postal,
    trackingId,
  })

  const selectedPricingOption = programPricingOptions?.find(
    ({ id }) => id === pricingId
  )
  const selectedTermLength = useMemo(() => {
    const priceTermMonths = selectedPricingOption?.priceTermMonths
    if (priceTermMonths === 0) {
      return 'Unlimited'
    } else if (!priceTermMonths) {
      return ''
    }

    return `${priceTermMonths} Months`
  }, [selectedPricingOption?.priceTermMonths])

  return (
    <Modal open={isOpen} onClose={onClose}>
      {!isLoading && (
        <Box className={classes.base}>
          <Grid container item xs={12}>
            <Grid item>
              <Typography variant="h1">Switching Programs</Typography>
            </Grid>
            <div style={{ flex: 1 }} />
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
          <Grid className={classes.body}>
            <Grid container spacing={2}>
              <Grid item className={classes.programSection}>
                <Typography variant="caption">Program Type</Typography>
                <Select
                  value={program?.id || 'temp-program'}
                  label="Program Type"
                  onChange={(evt) => setProgram(evt.target.value)}
                >
                  {map(programs.programsList, (programOption) => {
                    return (
                      <MenuItem key={programOption.id} value={programOption.id}>
                        {programOption.description}
                      </MenuItem>
                    )
                  })}
                </Select>
              </Grid>
              <Grid item className={classes.programSection}>
                <Typography variant="caption">Start Fee/Monthly Fee</Typography>
                <Select
                  displayEmpty
                  label="Start Fee/Monthly Fee"
                  value={pricingId}
                  onChange={(evt) => setPricingId(evt.target.value)}
                >
                  {map(programPricingOptions, (pricingOption) => {
                    return (
                      <MenuItem key={pricingOption.id} value={pricingOption.id}>
                        {formatCents(pricingOption.startFee)} /
                        {formatCents(pricingOption.monthly)}
                      </MenuItem>
                    )
                  })}
                </Select>
              </Grid>
              <Grid item className={classes.programSection}>
                <Typography variant="caption">Term Length</Typography>
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  value={selectedTermLength}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography className={classes.disclaimer}>
                By clicking Confirm Program Change, the customer acknowledges
                that switching programs may change their start fee, monthly fee,
                how long the current price is active, and other various fees.
                The customer also acknowledges that they must go through the CQE
                approval process again to qualify for this particular program.
              </Typography>
            </Grid>
            <Grid container justifyContent="center">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => saveProgramSelection()}
              >
                Confirm Program Change
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Modal>
  )
}
