import { FlagHandler } from './flag-handler'

console.log('Using Prod Flags')

export const FeatureFlags: Record<string, boolean> = new Proxy(
  {
    fleet488: true, // https://nxcr.atlassian.net/browse/FLEET-488
    fleet561: false,
    aty310: true, // Scheduling feature
    fleet624: false, // Scheduling from reservations
  },
  FlagHandler
)
