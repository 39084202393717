import React, { FC } from 'react'
import { QualificationTier, CustomerState } from '@nxcr-org/web-api-interface/lib/domain_pb'
import { Autocomplete, Card, Typography, Box, TextField, Link, FormGroup, FormControlLabel, Checkbox } from '@mui/material'

import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { useCustomersTable } from './useCustomerTable'
import { TableSearch } from '../../../shared/TableSearch'
import { formatEnum } from '../../../../utils/formatEnum'



const qualificationTier = formatEnum(QualificationTier)
const customerStates = formatEnum(CustomerState)

export const CustomerTable: FC = () => {

  const {
    customers,
    total,
    page,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    isLoading,
    setQuery,
    //programs,
    search,
    setState,
    setCQEStatus,
    setBlankNames
  } = useCustomersTable()

  const columns: GridColumns = [
    {
      field: 'customerName',
      headerName: 'Customer Name',
      cellClassName: 'primaryAccent',
      flex: 0.75,
      renderCell: (props) => (
        <Link sx={{ fontWeight: 'bold' }} href={`/customers/${props.row.id}`}>
          {props.row.customerName}
        </Link>
      ),
    },
    {
      field: 'accountNumber',
      headerName: 'Account Number',
      flex: 0.40,
    },
    {
      field: 'state',
      headerName: 'State',
      flex: 0.75,
    },
    {
      field: 'cqeStatus',
      headerName: 'CQE Status',
      flex: 0.75,
    },
    {
      field: 'isDepositPaid',
      headerName: 'Deposit Paid',
      flex: 0.5,
    },

    {
      field: 'createdAt',
      headerName: 'Creation Date',
      flex: 0.5,
    },
    {
      field: 'vehicle',
      headerName: 'Vehicle',
      flex: 1,
    }
  ]

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        p: 3,
      }}
    >
      <Typography sx={{ marginLeft: '5px' }} variant="h1">
        Customers
      </Typography>
      <Card>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <TableSearch
            label="id, customer name, account number..."
            setQuery={setQuery}
            search={search}
          />
          <Autocomplete
            sx={{ width: '50%', paddingRight: '10px', marginTop: '-8px' }}
            disableClearable
            options={[
              { id: undefined, description: 'All' },
              { id: 0, description: customerStates.ACCOUNT_PENDING.label },
              { id: 1, description: customerStates.ACCOUNT_CREATED.label },
              { id: 2, description: customerStates.PENDING_SECURITY_DEPOSIT.label },
              { id: 3, description: customerStates.SECURITY_DEPOSIT_COMPLETE.label },
              { id: 4, description: customerStates.PICKUP_SCHEDULED.label },
              { id: 5, description: customerStates.PENDING_PAYMENT_METHODS.label },
              { id: 6, description: customerStates.PENDING_INSURANCE.label },
              { id: 7, description: customerStates.PENDING_PICKUP.label },
              { id: 8, description: customerStates.PENDING_INSPECTION.label },
              { id: 9, description: customerStates.PENDING_SIGNATURE.label },
              { id: 10, description: customerStates.SUBSCRIPTION_ACTIVATION.label },

            ]}
            //@ts-ignore
            getOptionLabel={(option) => option.description}
            renderInput={(inputProps) => (
              <TextField
                {...inputProps}
                label="State"
                margin="normal"
                variant="outlined"
              />
            )}
            onChange={(e, value) => setState((value as { id: number }).id)}
          />
          <Autocomplete
            sx={{ width: '50%', paddingRight: '10px', marginTop: '-8px' }}
            disableClearable
            options={[
              { id: undefined, description: 'All' },
              { id: 0, description: qualificationTier.QUALIFICATION_PENDING.label },
              { id: 1, description: qualificationTier.DISQUALIFIED.label },
              { id: 2, description: qualificationTier.TIER_1.label },
              { id: 3, description: qualificationTier.TIER_2.label },
              { id: 4, description: qualificationTier.TIER_3.label },
              { id: 5, description: qualificationTier.THIN_FILE.label },
              { id: 6, description: qualificationTier.BORDERLINE_FICO.label },
              { id: 7, description: qualificationTier.NO_HIT.label },
              { id: 8, description: qualificationTier.LIQUIDITY_GENERIC.label },
              { id: 10, description: qualificationTier.ERROR.label },
              { id: 11, description: qualificationTier.NEEDS_REVIEW.label },
            ]}
            //@ts-ignore
            getOptionLabel={(option) => option.description}
            renderInput={(inputProps) => (
              <TextField
                {...inputProps}
                label="CQE Status "
                margin="normal"
                variant="outlined"
              />
            )}
            onChange={(e, value) =>setCQEStatus((value as { id: number }).id)}
          />
          <FormGroup sx={{justifyContent: 'center'}}>
            <FormControlLabel sx={{fontSize: '14px', width: '140px'}} control={
              <Checkbox color="primary" onChange={(e)=>setBlankNames(e.target.checked)}/>
            } label='Blank Names'/>
          </FormGroup>
        </Box>
        <DataGrid
          columns={columns}
          rows={customers}
          rowCount={total}
          pageSize={rowsPerPage}
          page={page}
          autoHeight
          loading={isLoading}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
        />
        {/* </Grid> */}
      </Card>
    </Box>
  )
}
