import { Program } from '@nxcr-org/web-api-interface/lib/subscription_pb'
import { find } from 'lodash'
import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { NexusService } from 'global-apis/nexus-service'
import { getPricingOptionsForProgram } from '../ProgramPricingBlock/useProgramPricingBlock'

export function useChangeProgramDialog({
  currentProgram,
  reservationId,
  onClose,
  modelOptions,
  postal,
  trackingId,
}: {
  customerId: string
  reservationId: string
  currentProgram: Program.AsObject
  onClose: () => void
  modelOptions
  postal: string
  trackingId: string
}) {
  const queryClient = useQueryClient()
  const [program, setSelectedProgram] = useState(null)
  const [pricingId, setPricingId] = useState<string>('')

  const {
    data: programOptions,
    error: programOptionsError,
    isLoading,
  } = useQuery(
    ['/programOptions'],
    () => {
      return NexusService.getProgramofferings()
    },
    {
      placeholderData: {
        programsList: [],
      },
      onError() {
        console.log('Error: ', programOptionsError)
      },
      onSuccess(data) {
        const selectedProgram = find(data.programsList, {
          id: program?.id || currentProgram.id,
        })

        setPricingId(selectedProgram?.pricingOptionsList?.[0]?.id)
        setSelectedProgram(selectedProgram)
      },
    }
  )

  const setProgram = (newProgramId: string) => {
    const newProgram = find(programOptions.programsList, {
      id: newProgramId,
    })

    setSelectedProgram(newProgram)
    setPricingId(newProgram.pricingOptionsList?.[0]?.id)
  }

  const { mutate: saveProgramSelection } = useMutation(
    () => {
      const pricingOption = find(
        getPricingOptionsForProgram(programOptions?.programsList, program.id),
        {
          id: pricingId,
        }
      )
      return NexusService.UpdateProgramReservation({
        reservationId,
        deposit: pricingOption.deposit,
        startFee: pricingOption.startFee,
        monthlyRent: pricingOption.monthly,
        color: modelOptions.color,
        secondaryColor: modelOptions.secondaryColor,
        make: program.make,
        model: program.model,
        postal,
        trackingId,
      })
        .then(onClose)
        .catch(onClose)
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['/reservation', reservationId])
      },
    }
  )

  // TEMP - needed since program options might be cached initially
  useEffect(() => {
    if (programOptions) {
      const selectedProgram = find(programOptions.programsList, {
        id: currentProgram.id,
      })

      setPricingId(selectedProgram?.pricingOptionsList?.[0]?.id)
      setSelectedProgram(selectedProgram)
    }
  }, [])

  return {
    programs: programOptions,
    pricingId,
    isLoading,
    program,
    setProgram,
    setPricingId,
    saveProgramSelection,
    programPricingOptions: getPricingOptionsForProgram(
      programOptions?.programsList,
      program?.id
    ),
  }
}
