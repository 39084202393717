import { NexusServicePromiseClient } from '@nxcr-org/web-api-interface/lib/nexus_service_grpc_web_pb'
import {
  GetCustomerRequest,
  GetVehicleMapRequest,
} from '@nxcr-org/web-api-interface/lib/nexus_service_pb'
import { oktaAuth, OktaAuthInterceptor } from '../../okta/config'
import { Env } from '../fleet-management/api/env'
import { isEmpty, isString, map } from 'lodash'
import {
  listPromosRequest,
  createPromoCode,
  listPromoPartners,
  createPromoPartner,
  getCustomerInvoices,
  getAccountBalance,
  getProgramofferings,
  getBillingStatus,
  getSubscriptionById,
  listRecurringBillableItems,
  updateRecurringBillableItems,
  listCustomerSubscriptions,
  removeMileageRecurringBillableItem,
  payInvoice,
  UpdateProgramReservation,
} from './nexus-service-payments'
import {
  getLatestCustody,
  LogMilesPurchased,
  deleteMilesPurchasedLog,
} from './nexus-service-fleet'
import {
  setPickupDate,
  setReservationProgram,
  listAppointments,
  cancelAppointment,
  createAppointment,
  updateAppointment,
  listAppointmentsByAccountNumber,
} from './nexus-service-scheduling'
import {
  listAppointmentTypeConfigs,
  listDealerships,
  listDealershipUsers,
  setAppointmentTypeConfigs,
} from './nexus-service-settings'
import { getBffCustomers } from './nexus-bff-service-customers'
import {
  ListParams,
  PaginationParams,
  OrderParams,
  QueryParams,
} from '@nxcr-org/web-api-interface/lib/web_pb'
import { UpdateCustomerWithFakePhone } from './nexus-service-customer'

//Service Exports
export const NexusService = {
  getBffCustomers,
  getCustomer,
  setPickupDate,
  setReservationProgram,
  listAppointments,
  cancelAppointment,
  createStatement: notImplementedFunction,
  createAppointment,
  updateAppointment,
  listPromosRequest,
  listAppointmentsByAccountNumber,
  createPromoCode,
  listPromoPartners,
  createPromoPartner,
  getCustomerInvoices,
  getVehicles: getMapVehicles,
  listDealerships,
  listDealershipUsers,
  listAppointmentTypeConfigs,
  setAppointmentTypeConfigs,
  listTransactions: notImplementedFunction,
  getAccountBalance,
  getLatestCustody,
  getProgramofferings,
  getBillingStatus,
  getSubscriptionById,
  listCustomerSubscriptions,
  listRecurringBillableItems,
  updateRecurringBillableItems,
  removeMileageRecurringBillableItem,
  payInvoice,
  LogMilesPurchased,
  deleteMilesPurchasedLog,
  UpdateProgramReservation,
  UpdateCustomerWithFakePhone,
}

function getNexusService(): NexusServicePromiseClient {
  const idToken = oktaAuth.getIdToken()
  const target = Env.endpoints.fleet

  return new NexusServicePromiseClient(target, null, {
    unaryInterceptors: [new OktaAuthInterceptor(idToken)],
    'grpc-node.max_session_memory': 1000,
    'grpc-node.max_send_message_length': 1024 * 1024 * 100,
    'grpc-node.max_receive_message_length': 1024 * 1024 * 100,
  })
}

function getCustomer(params: GetCustomerRequest.AsObject) {
  const client = getNexusService()
  const request = buildGetCustomerRequest(params)
  return client.getCustomer(request).then((res) => {
    return res.toObject()
  })
}

export function buildGetCustomerRequest(params: GetCustomerRequest.AsObject) {
  const request = new GetCustomerRequest()
  if (!isString(params.customerId)) {
    throw new Error('Request requires customer ID')
  }
  request.setCustomerId(params.customerId)
  return request
}

function getMapVehicles(params: GetVehicleMapRequest.AsObject) {
  const client = getNexusService()
  const request = buildGetVehiclesRequest(params)
  return client.getMapVehicles(request).then((res) => res.toObject())
}

/**
 * Place in more central location.
 * @param params
 * @returns
 */
function buildListParams(params: GetVehicleMapRequest.AsObject) {
  const status = new ListParams()

  if (params?.status?.queryList && params?.status?.queryList.length > 0) {
    const queries = mapQueryParams(params?.status?.queryList)
    status.setQueryList(queries)
  }

  if (params?.status?.orderList && params?.status?.orderList.length > 0) {
    const order = mapOrderList(params?.status?.orderList)
    status.setOrderList(order)
  }

  if (params?.status?.pagination && !isEmpty(params?.status?.pagination)) {
    const pagination = buildPagination(params?.status?.pagination)
    status.setPagination(pagination)
  }

  return status
}

function buildPagination(params: ListParams.AsObject['pagination']) {
  const pagination = new PaginationParams()

  pagination.setLimit(params.limit)
  pagination.setOffset(params.offset)

  return pagination
}

function mapQueryParams(params: ListParams.AsObject['queryList'] = []) {
  return map(params, (query) => {
    const queryParams = new QueryParams()

    queryParams.setProperty(query.property)
    queryParams.setValue(query.value)
    queryParams.setModifier(query.modifier)

    return queryParams
  })
}

function mapOrderList(params: ListParams.AsObject['orderList'] = []) {
  return map(params, (order) => {
    const orderParam = new OrderParams()
    orderParam.setDirection(order.direction)
    orderParam.setProperty(order.property)
    return orderParam
  })
}

export function buildGetVehiclesRequest(params: GetVehicleMapRequest.AsObject) {
  const request = new GetVehicleMapRequest()
  const listParams = buildListParams(params)

  request.setStatus(listParams)
  request.setCustomerName(params.customerName)
  request.setVehicleMake(params.vehicleMake)
  request.setVehicleModel(params.vehicleModel)
  request.setVehicleVin(params.vehicleVin)

  return request
}

// create not implemented function that returns a promise
function notImplementedFunction(params: any) {
  console.debug('called notImplementedFunction', params)
  return new Promise((resolve, reject) => {
    reject('Not Implemented')
  })
}
