import { CustomerServicePromiseClient } from '@nxcr-org/web-api-interface/lib/gateway_service_grpc_web_pb'

import { oktaAuth, OktaAuthInterceptor } from '../../okta/config'
import { Env } from '../fleet-management/api/env'
import { UpdateCustomerWithFakePhoneRequest } from '@nxcr-org/web-api-interface/lib/customer_service_pb'
function getCustomerService(): CustomerServicePromiseClient {
  const idToken = oktaAuth.getIdToken()
  const target = Env.endpoints.fleet

  return new CustomerServicePromiseClient(target, null, {
    unaryInterceptors: [new OktaAuthInterceptor(idToken)],
    'grpc-node.max_session_memory': 1000,
    'grpc-node.max_send_message_length': 1024 * 1024 * 100,
    'grpc-node.max_receive_message_length': 1024 * 1024 * 100,
  })
}
export function UpdateCustomerWithFakePhone(customerId) {
  const client = getCustomerService()
  const request = new UpdateCustomerWithFakePhoneRequest()
  request.setCustomerId(customerId)
  return client.updateCustomerWithFakePhone(request).then((res) => {
    return res.toObject()
  })
}
