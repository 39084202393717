import reservation, {
  ReservationStatus,
} from '@nxcr-org/web-api-interface/lib/subscription_pb'
import { QualificationTier } from '@nxcr-org/web-api-interface/lib/domain_pb'
import { RESERVATION_STATUSES } from '../utils'

export function getOrderStatus(reservationStatus, qualificationStatus) {
  let orderStatus

  /** Using customer qualification status to identify the order status
      if reservation status is paid and customer is qualified
            .order status is Qualified
      if reservation status is paid and customer is DisQualified
            .order status is DisQualified
      if reservation status is paid and customer is qualification Pending
            .order status is Paid
    Aligns with: https://www.notion.so/nxcr/Customer-Approval-f13e968845fa499a8015e80a3ee881ab#e744d093e5744e6b9abff979960cb748
   */
  if (reservation.ReservationStatus.PAID === reservationStatus) {
    const isApproved =
      qualificationStatus === QualificationTier.TIER_1 ||
      qualificationStatus === QualificationTier.TIER_2 ||
      qualificationStatus === QualificationTier.TIER_3

    if (isApproved) {
      orderStatus = 'Qualified'
    } else if (QualificationTier.DISQUALIFIED === qualificationStatus) {
      orderStatus = 'Disqualified'
    } else if (
      QualificationTier.QUALIFICATION_PENDING === qualificationStatus
    ) {
      orderStatus = reservationStatus
    }
  } else {
    orderStatus = reservationStatus
  }

  switch (orderStatus) {
    case reservation.ReservationStatus.CONVERTED:
      return RESERVATION_STATUSES.ACTIVATED
    case reservation.ReservationStatus.REFUND_REQUESTED:
    case reservation.ReservationStatus.REJECTED:
      return RESERVATION_STATUSES.CANCELLED
    case reservation.ReservationStatus.ASSIGNED_VEHICLE:
      return RESERVATION_STATUSES.ASSIGNED_VEHICLE
    case reservation.ReservationStatus.CREATED:
      return RESERVATION_STATUSES.PENDING
    case reservation.ReservationStatus.PAID:
      return RESERVATION_STATUSES.PAID
    case 'Qualified':
      return RESERVATION_STATUSES.QUALIFIED
    case 'Disqualified':
      return RESERVATION_STATUSES.DISQUALIFIED
    case ReservationStatus.VEHICLE_UNASSIGNED:
      return RESERVATION_STATUSES.QUALIFIED
    default:
      return ''
  }
}
