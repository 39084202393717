import { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { find, isEmpty, keys, lowerCase, map, startCase } from 'lodash'
import {
  useIsFetching,
  useIsMutating,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { useOktaAuth } from '@okta/okta-react'

import {
  ActivityState,
  Address,
  Customer,
  CustomerInfo,
  QualificationTier,
  QualificationTierMap,
  Score,
  Subscription,
} from '@nxcr-org/web-api-interface/lib/domain_pb'
import {
  Program,
  Reservation,
  ReservationStatus,
} from '@nxcr-org/web-api-interface/lib/subscription_pb'
import { GetReservationResponse } from '@nxcr-org/web-api-interface/lib/subscription_service_pb'
import { Vehicle } from '@nxcr-org/web-api-interface/lib/vehicle_pb'

import { NexusService } from 'global-apis/nexus-service'
import { FleetService } from '../../api/fleet-service'
import { ReservationService } from '../../api/reservation-service'
import { SubscriptionService } from '../../api/subscription-service'
import { getMileage } from '../../utils'
import { getIsAdmin } from '../../../../utils/getIsAdmin'
import { getOrderStatus } from '../../api/reservation-service.hooks'
import { CQEService } from '../../../global-APIs/nexus-service-cqe'
import { Snack, SnackbarContext } from '../../../shared/Snackbar'

export function useReservation() {
  const queryClient = useQueryClient()
  const { reservationId } = useParams<{ reservationId: string }>()
  const { authState } = useOktaAuth()
  const [currentNote, setCurrentNote] = useState('')
  const [changeProgramOpen, setChangeProgramOpen] = useState(false)
  const [overrideDisqualificationVendor, setOverrideDisqualificationVendor] =
    useState(undefined)
  const userGroups = authState?.idToken?.claims?.groups
  const isAdmin = getIsAdmin(userGroups)

  const { setSnack } = useContext(SnackbarContext)

  const toggleChangeProgram = () => {
    setChangeProgramOpen((currentState) => !currentState)
  }

  const {
    data: reservation,
    isSuccess: reservationSuccess,
    error: reservationError,
  } = useQuery(
    ['/reservation', reservationId],
    () => {
      return ReservationService.getReservation(reservationId).then(
        handleReservationResponse
      )
    },
    {
      // @ts-ignore :: Ignore as this just stops the UI from failing while loading
      placeholderData: getPlaceholderReservation(),
    }
  )

  const { data: mostRecentNote } = useQuery(
    ['notes', reservation?.customer?.id],
    () => {
      return CQEService.getCustomerNotes({
        customerId: reservation?.customer?.id,
      })
        .catch((err) => {
          console.error('CQE error', err)
          return {
            notesList: [{ noteText: 'CQE SERVICE ERROR ON NOTE FETCH' }],
          }
        })
        .then((notesResponse) => {
          const { notesList } = notesResponse
          const lastestNote = notesList[0]?.noteText || ''
          setCurrentNote(lastestNote)

          return lastestNote
        })
    },
    {
      enabled: reservationSuccess && !!reservation?.customer?.id,
    }
  )

  const { data: cqeScores } = useQuery(
    ['scores', reservation?.customer?.id],
    () => {
      return CQEService.getCustomerCQEScores({
        customerId: reservation?.customer?.id,
      }).catch((err) => {
        console.error('getCQEScoresError', err)
        return {
          scoresList: [],
        }
      })
    },
    {
      enabled: !!reservation?.customer?.id,
    }
  )

  const {
    mutateAsync: overrideQualification,
    isSuccess: overrideQualificationSuccess,
  } = useMutation(
    (result: ProcessCustomerArgs) => {
      if (result.decision === ProcessOptions.approved) {
        return CQEService.overrideCustomerQualification({
          customerId: reservation.customer.id,
          tier: result.decision,
          disqualifiedScoreId: '',
        })
      }

      if (!overrideDisqualificationVendor) {
        throw new Error('No score set for disqualification')
      }

      return CQEService.overrideCustomerQualification({
        customerId: reservation.customer.id,
        tier: result.decision,
        disqualifiedScoreId: overrideDisqualificationVendor.id,
      })
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['/reservation', reservationId])
      },
    }
  )

  const { mutate: assignVehicle } = useMutation(
    (vehicleId: string) => {
      return SubscriptionService.addVehicleAssignment({
        vehicleId,
        subscriptionId: reservation.subscriptionId,
      })
    },
    {
      async onSuccess() {
        await queryClient.refetchQueries(['/reservation', reservationId])
        await queryClient.invalidateQueries(['getVehicles'])
      },
      onError(error: Error) {
        setSnack(
          new Snack({
            message: `Error assigning vehicle: \n\n${error?.message}`,
            color: 'error',
            open: true,
          })
        )
      },
    }
  )

  const { mutate: unassignVehicle } = useMutation(
    () => {
      return SubscriptionService.removeVehicleAssignment({
        subscriptionId: reservation.subscriptionId,
      })
    },
    {
      async onSuccess() {
        await queryClient.refetchQueries(['/reservation', reservationId])
        await queryClient.invalidateQueries(['getVehicles'])
      },
      onError(error: Error) {
        setSnack(
          new Snack({
            message: `Error unassigning vehicle: \n\n${error?.message}`,
            color: 'error',
            open: true,
          })
        )
      },
    }
  )

  const {
    mutate: recalculateCustomerScores,
    error: cqeError,
    isSuccess: recalculateCustomerScoresSuccess,
  } = useMutation(
    () => {
      return CQEService.recalculateCustomerQualification({
        customerId: reservation?.customer?.id,
        scoreNamesList: [0, 1, 2, 3],
        skipQualification: false,
      })
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['/reservation', reservationId])
      },
    }
  )

  const { mutate: addNote } = useMutation(
    () => {
      const data = {
        customerId: reservation.customer.id,
        noteText: currentNote,
      }

      return CQEService.addCustomerNote(data)
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['/reservation', reservationId])
        queryClient.invalidateQueries(['notes', reservation?.customer?.id])
      },
    }
  )

  const { mutate: cancelReservation, error: cancelError } = useMutation(
    ({
      actionReason,
      userType,
    }: {
      actionReason: any
      userType: Subscription.SubscriptionAction.UserTypeMap[keyof Subscription.SubscriptionAction.UserTypeMap]
    }) => {
      return ReservationService.cancelReservation({
        actionReason,
        userType,
        subscriptionId: reservation.reservation.id,
        customerId: reservation.customer.id,
        reservationId: reservation.reservation.id,
        customerReason: '',
      }).catch((err) => {
        console.error({
          message: 'Error cancelling subscription',
          err,
          params: {
            actionReason,
            userType,
          },
        })

        // ! Allow UI to handle but log error so that datadog picks up additional data
        throw err
      })
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['/reservation', reservationId])
      },
    }
  )

  const setReload = () => {
    queryClient.invalidateQueries(['/reservation', reservationId])
  }

  const isFetching = useIsFetching(['/reservation'])
  const isMutating = useIsMutating()

  return {
    getModelOptions,
    authState,
    reservation,
    addNote,
    mostRecentNote,
    assignVehicle,
    unassignVehicle,
    overrideQualification,
    overrideQualificationSuccess,
    recalculateCustomerScores,
    recalculateCustomerScoresSuccess,
    cancelReservation,
    isAdmin,
    currentNote,
    setCurrentNote,
    setReload,
    reservationError,
    cqeError,
    cqeScores: cqeScores?.scoresList,
    cancelError,
    changeProgramOpen,
    toggleChangeProgram,
    isLoading: isFetching > 0 || isMutating > 0,
    disqualificationVendor: getDisqualificationVendor(cqeScores?.scoresList),
    overrideDisqualificationVendor,
    setOverrideDisqualificationVendor,
  }
}

export function getIsQualified(
  qualificationTier: QualificationTierMap[keyof QualificationTierMap]
) {
  return (
    qualificationTier === QualificationTier.TIER_1 ||
    qualificationTier === QualificationTier.TIER_2 ||
    qualificationTier === QualificationTier.TIER_3
  )
}

const filterLineItem = (items, itemType, productType = undefined) => {
  return (
    items?.find((lineItem) => {
      if (productType === undefined) {
        return lineItem.itemType === itemType
      }

      return lineItem.product?.productType === productType
    })?.amount / 100
  ) // it's in cents
}

function getPayments(vehicle?: Vehicle.AsObject) {
  if (!vehicle || isEmpty(vehicle)) {
    return {
      monthly: 0,
      securityDeposit: 0,
      start: 0,
    }
  }

  return {
    monthly: filterLineItem(vehicle?.defaultLineItemsList, 0, 1),
    securityDeposit: filterLineItem(vehicle?.defaultLineItemsList, 0, 2),
    start: filterLineItem(vehicle?.defaultLineItemsList, 1),
  }
}

function getPlaceholderReservation() {
  return {
    customer: {
      subscriptionsList: [{}],
    },
    customerInfo: {},
    isQualified: false,
    reservationStatus: '',
    reservation: {
      customer: {
        subscriptionsList: [{}],
      },
    },
    vehicle: {},
    isCancelled: false,
    qualifications: {
      scores: [],
      hasQualifications: false,
    },
    payments: {
      monthly: 0,
      securityDeposit: 0,
      start: 0,
    },
    assignedVehicle: {},
  }
}

async function handleReservationResponse(res: GetReservationResponse.AsObject) {
  const subscriptionsList = (
    await NexusService.listCustomerSubscriptions(res.customer.id)
  ).subscriptionsList

  const activeSubscription = find(subscriptionsList, {
    status: 'ACTIVE',
  })

  const activeOrFirstSubscription = activeSubscription
    ? activeSubscription
    : subscriptionsList[0]

  const vehicleId = activeOrFirstSubscription.vehicleId

  const assignedVehicle = vehicleId
    ? await getAssignedVehicle(vehicleId)
    : undefined
  const customerInfo = formatCustomerInfo(res)
  const isQualified = getIsQualified(customerInfo.qualificationTier)
  const isModel3 = !!res.reservation.model3

  return {
    ...res,
    isModel3,
    customerInfo,
    isQualified,
    reservationStatus: getOrderStatus(
      res?.reservation?.status,
      res?.customer?.customerInfo?.qualificationTier
    ),
    reservation: formatReservation(res.reservation),
    vehicle: getReservationVehicle(res, assignedVehicle),
    isCancelled: getIsCancelled(res.reservation),
    qualifications: {
      scores: getQualification(res?.customer?.customerInfo?.scoresList),
      hasQualifications: res?.customer?.customerInfo?.scoresList?.length > 0,
    },
    payments: getPayments(assignedVehicle),
    assignedVehicle: assignedVehicle,
    subscriptionId: activeOrFirstSubscription.id,
  }
}

async function getAssignedVehicle(
  vehicleId: string
): Promise<Vehicle.AsObject | undefined> {
  return FleetService.getVehicle(vehicleId)
    .then((res) => {
      return res.vehicle as Vehicle.AsObject
    })
    .catch((err) => {
      console.error({
        errorOnGetAssignedVehicle: err,
      })
      return undefined
    })
}

export function getModelOptions(reservation?: Reservation.AsObject) {
  if (reservation?.model3) {
    return reservation?.model3
  } else if (reservation?.modelY) {
    return reservation?.modelY
  }
}

export function getModelOptionsName(reservation?: Reservation.AsObject) {
  if (reservation?.model3) {
    return Reservation.ProgramOptionsCase.MODEL_3
  } else if (reservation?.modelY) {
    return Reservation.ProgramOptionsCase.MODEL_Y
  }
}

export function getReservationVehicle(
  reservation: GetReservationResponse.AsObject,
  vehicle?: Vehicle.AsObject
): VehicleBlockProps {
  const modelOptions = getModelOptions(reservation.reservation)
  const colorPreference =
    modelOptions.color === 'any' ? 'No Preference' : modelOptions.color

  return {
    make: vehicle?.make,
    model: vehicle?.model,
    colorPreference,
    mileage: getMileage(vehicle),
    dateListed: vehicle?.inServiceDate,
    vin: vehicle?.vin || 'N/A',
    startFee: modelOptions.startFee,
    monthly: modelOptions.monthly,
    deposit: modelOptions.deposit,
    trim: vehicle?.trim || 'N/A',
    termLength: modelOptions.priceTermMonths,
  }
}

export function getIsCancelled(
  reservation: GetReservationResponse.AsObject['reservation']
) {
  return reservation.status === ReservationStatus.REFUND_REQUESTED
}

export function getQualification(
  scores: Score.AsObject[]
): FormattedQualification[] {
  return map(scores, (score) => {
    const timeIsInNano =
      score.scoreName === 'liquidity' || score.scoreName === 'fico'

    const qualificationTime = timeIsInNano
      ? new Date(Math.round(score.timestamp / 1000000))
      : new Date(score.timestamp)

    return {
      qualificationTime: format(qualificationTime, 'hh:mm aa'),
      qualificationDate: format(qualificationTime, 'MM/dd/yyyy'),
      result: score.isPassing ? 'Accepted' : 'Rejected',
      reason: 'TBD',
      score: score.scoreValue,
      tier: 'TBD',
      vendor: score.vendorName,
      sourceData: scores || [],
      isDisqualifiedScore: score.isDisqualifiedScore,
    }
  })
}

const getAddressFromAddressList = (addressesList, addressType) =>
  addressesList.find((address) => address.addressType == addressType)

export function formatCustomerInfo({
  customer,
  reservation,
}: GetReservationResponse.AsObject): FormattedCustomerInfo {
  return {
    ...customer?.customerInfo,
    accountNumber: customer?.accountNumber,
    reservationNumber: reservation?.reservationNumber,
    fullName: `${customer?.customerInfo?.firstName} ${customer?.customerInfo?.lastName}`,
    zipcode: findZipcode(customer?.customerInfo?.addressesList),
    dealer: 'Autonomy',
    hasVehicleAssigned: isCustomerVehicleAssigned(customer),
    garagingAddress: getAddressFromAddressList(
      customer?.customerInfo?.addressesList,
      Address.AddressType.PARKING
    ),
    licenseAddress: getAddressFromAddressList(
      customer?.customerInfo?.addressesList,
      Address.AddressType.LICENSE
    ),
    contactAddress: getAddressFromAddressList(
      customer?.customerInfo?.addressesList,
      Address.AddressType.CONTACT
    ),
    //  @TODO: Should be currentActivityState
    currentActivity: getCurrentActivity(customer?.activityState),
  }
}

export function isCustomerVehicleAssigned(
  customer: GetReservationResponse.AsObject['customer']
) {
  const subscriptionsList = customer.subscriptionsList || []

  if (subscriptionsList.length === 0) {
    return false
  }

  if (subscriptionsList.length === 1) {
    return subscriptionsList[0].vehicleId?.length > 0
  }

  const assignedVehicle = find(subscriptionsList, (subscription) => {
    const subscriptionIsntClosed =
      subscription.status !== Subscription.Status.CLOSED
    return subscription.vehicleId?.length > 0 && subscriptionIsntClosed
  })

  return !!assignedVehicle
}

export function findZipcode(
  addresses: GetReservationResponse.AsObject['customer']['customerInfo']['addressesList']
) {
  if (addresses.length === 0) {
    return 'N/A'
  }

  return addresses[0].postal
}

export function formatReservation(
  reservation: Reservation.AsObject
): FormattedReservation {
  return {
    ...reservation,
    formattedCreated: format(new Date(reservation.created), 'MM/dd/yy HH:MM'),
  }
}

export function getFormattedActivities() {
  return map(keys(ActivityState), (activity) => startCase(lowerCase(activity)))
}

export function getCurrentActivity(
  customerActivityState: Customer.AsObject['activityState']
) {
  const formattedActivities = getFormattedActivities()
  return formattedActivities[customerActivityState]
}

export function getDisqualificationVendor(
  cqeScores: Score.AsObject[]
): Score.AsObject {
  return cqeScores?.find((score) => score.isDisqualifiedScore)
}

export interface VehicleBlockProps {
  make: string
  model: string
  colorPreference: string
  vin: string
  mileage: string
  dateListed?: number
  monthly: number
  startFee: number
  deposit: number
  trim: string
  termLength?: number
}

export interface FormattedReservationResponse {
  reservation?: FormattedReservation
  customerInfo?: FormattedCustomerInfo
  customer?: Customer.AsObject
  program?: Program.AsObject
  vehicle?: VehicleBlockProps
  qualifications?: QualificationBlockProps
  isCancelled: boolean
  reservationStatus: string
  payments?: {
    monthly: number
    securityDeposit: number
    start: number
  }
  assignedVehicle?: Vehicle.AsObject
  isQualified?: boolean
}

export type FormattedReservation = Reservation.AsObject & {
  formattedCreated: string
}

export type FormattedCustomerInfo = CustomerInfo.AsObject & {
  fullName: string
  accountNumber: string
  reservationNumber: string
  zipcode: string
  dealer: string
  hasVehicleAssigned: boolean
  currentActivity: string
  garagingAddress: Address.AsObject
  licenseAddress: Address.AsObject
  contactAddress: Address.AsObject
  // currentStatus: string
}

export interface FormattedQualification {
  qualificationTime: string
  qualificationDate: string
  result: string
  reason: string
  score: string
  tier: string
  vendor: string
  sourceData: any
  isDisqualifiedScore: boolean
}

export type QualificationBlockProps = {
  scores: FormattedQualification[]
}

export enum ProcessOptions {
  unchanged,
  rejected,
  approved,
  recalculate,
}

export type ProcessCustomerArgs = {
  decision: ProcessOptions
  note: string
}
