// source: insurance_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var domain_pb = require('./domain_pb.js');
goog.object.extend(proto, domain_pb);
var insurance_pb = require('./insurance_pb.js');
goog.object.extend(proto, insurance_pb);
goog.exportSymbol('proto.insurance_service.BulkUpdatePoliciesRequest', null, global);
goog.exportSymbol('proto.insurance_service.BulkUpdatePoliciesResponse', null, global);
goog.exportSymbol('proto.insurance_service.CancelInsurancePolicyRequest', null, global);
goog.exportSymbol('proto.insurance_service.CancelInsurancePolicyResponse', null, global);
goog.exportSymbol('proto.insurance_service.CreateAllInsuranceQuotesRequest', null, global);
goog.exportSymbol('proto.insurance_service.CreateAllInsuranceQuotesRequest.VehicleType', null, global);
goog.exportSymbol('proto.insurance_service.CreateAllInsuranceQuotesResponse', null, global);
goog.exportSymbol('proto.insurance_service.CreateInsurancePolicyRequest', null, global);
goog.exportSymbol('proto.insurance_service.CreateInsuranceQuoteRequest', null, global);
goog.exportSymbol('proto.insurance_service.CreateInsuranceQuoteRequest.VehicleType', null, global);
goog.exportSymbol('proto.insurance_service.CreateInsuranceQuoteResponse', null, global);
goog.exportSymbol('proto.insurance_service.CreatePolicyChangeQuoteRequest', null, global);
goog.exportSymbol('proto.insurance_service.CreatePolicyChangeQuoteResponse', null, global);
goog.exportSymbol('proto.insurance_service.CreatePolicyChangeRequest', null, global);
goog.exportSymbol('proto.insurance_service.CreatePolicyChangeResponse', null, global);
goog.exportSymbol('proto.insurance_service.CustomerIdentity', null, global);
goog.exportSymbol('proto.insurance_service.ExtendInsurancePolicyRequest', null, global);
goog.exportSymbol('proto.insurance_service.ExtendInsurancePolicyResponse', null, global);
goog.exportSymbol('proto.insurance_service.GetExternalPoliciesFromDsotRequest', null, global);
goog.exportSymbol('proto.insurance_service.GetExternalPoliciesFromDsotResponse', null, global);
goog.exportSymbol('proto.insurance_service.GetInsurancePoliciesResponse', null, global);
goog.exportSymbol('proto.insurance_service.GetInsurancePolicyRequest', null, global);
goog.exportSymbol('proto.insurance_service.GetInsurancePolicyResponse', null, global);
goog.exportSymbol('proto.insurance_service.GetInsuranceQuoteRequest', null, global);
goog.exportSymbol('proto.insurance_service.GetInsuranceQuoteResponse', null, global);
goog.exportSymbol('proto.insurance_service.GetInsuranceStatusRequest', null, global);
goog.exportSymbol('proto.insurance_service.GetInsuranceStatusResponse', null, global);
goog.exportSymbol('proto.insurance_service.GetInsuredDriversRequest', null, global);
goog.exportSymbol('proto.insurance_service.GetInsuredDriversResponse', null, global);
goog.exportSymbol('proto.insurance_service.GetOwnInsurancePoliciesRequest', null, global);
goog.exportSymbol('proto.insurance_service.GetUpdatedInsurancePolicyCardRequest', null, global);
goog.exportSymbol('proto.insurance_service.GetUpdatedInsurancePolicyCardResponse', null, global);
goog.exportSymbol('proto.insurance_service.ImportInsuranceDataRequest', null, global);
goog.exportSymbol('proto.insurance_service.ImportInsuranceDataResponse', null, global);
goog.exportSymbol('proto.insurance_service.InsurancePolicyCancelled', null, global);
goog.exportSymbol('proto.insurance_service.InsurancePolicyCreated', null, global);
goog.exportSymbol('proto.insurance_service.InsurancePolicyUpdated', null, global);
goog.exportSymbol('proto.insurance_service.InsuranceSplitLimits', null, global);
goog.exportSymbol('proto.insurance_service.IsQualifiedForLiberty', null, global);
goog.exportSymbol('proto.insurance_service.QualifyForLibertyInsuranceRequest', null, global);
goog.exportSymbol('proto.insurance_service.QualifyForLibertyInsuranceResponse', null, global);
goog.exportSymbol('proto.insurance_service.QuoteResponse', null, global);
goog.exportSymbol('proto.insurance_service.RequestSource', null, global);
goog.exportSymbol('proto.insurance_service.SelectInsuranceQuoteRequest', null, global);
goog.exportSymbol('proto.insurance_service.SelectInsuranceQuoteResponse', null, global);
goog.exportSymbol('proto.insurance_service.UpsertInsurancePoliciesRequest', null, global);
goog.exportSymbol('proto.insurance_service.UpsertInsurancePoliciesResponse', null, global);
goog.exportSymbol('proto.insurance_service.UpsertOwnInsurancePoliciesRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.CustomerIdentity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.CustomerIdentity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.CustomerIdentity.displayName = 'proto.insurance_service.CustomerIdentity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.UpsertOwnInsurancePoliciesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.insurance_service.UpsertOwnInsurancePoliciesRequest.repeatedFields_, null);
};
goog.inherits(proto.insurance_service.UpsertOwnInsurancePoliciesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.UpsertOwnInsurancePoliciesRequest.displayName = 'proto.insurance_service.UpsertOwnInsurancePoliciesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.UpsertInsurancePoliciesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.insurance_service.UpsertInsurancePoliciesRequest.repeatedFields_, null);
};
goog.inherits(proto.insurance_service.UpsertInsurancePoliciesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.UpsertInsurancePoliciesRequest.displayName = 'proto.insurance_service.UpsertInsurancePoliciesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.UpsertInsurancePoliciesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.UpsertInsurancePoliciesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.UpsertInsurancePoliciesResponse.displayName = 'proto.insurance_service.UpsertInsurancePoliciesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.CreateInsuranceQuoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.insurance_service.CreateInsuranceQuoteRequest.repeatedFields_, null);
};
goog.inherits(proto.insurance_service.CreateInsuranceQuoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.CreateInsuranceQuoteRequest.displayName = 'proto.insurance_service.CreateInsuranceQuoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.CreateInsuranceQuoteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.insurance_service.CreateInsuranceQuoteResponse.repeatedFields_, null);
};
goog.inherits(proto.insurance_service.CreateInsuranceQuoteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.CreateInsuranceQuoteResponse.displayName = 'proto.insurance_service.CreateInsuranceQuoteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.CreateAllInsuranceQuotesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.insurance_service.CreateAllInsuranceQuotesRequest.repeatedFields_, null);
};
goog.inherits(proto.insurance_service.CreateAllInsuranceQuotesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.CreateAllInsuranceQuotesRequest.displayName = 'proto.insurance_service.CreateAllInsuranceQuotesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.CreateAllInsuranceQuotesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.insurance_service.CreateAllInsuranceQuotesResponse.repeatedFields_, null);
};
goog.inherits(proto.insurance_service.CreateAllInsuranceQuotesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.CreateAllInsuranceQuotesResponse.displayName = 'proto.insurance_service.CreateAllInsuranceQuotesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.SelectInsuranceQuoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.SelectInsuranceQuoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.SelectInsuranceQuoteRequest.displayName = 'proto.insurance_service.SelectInsuranceQuoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.SelectInsuranceQuoteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.SelectInsuranceQuoteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.SelectInsuranceQuoteResponse.displayName = 'proto.insurance_service.SelectInsuranceQuoteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.GetInsuranceQuoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.GetInsuranceQuoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.GetInsuranceQuoteRequest.displayName = 'proto.insurance_service.GetInsuranceQuoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.GetInsuranceQuoteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.insurance_service.GetInsuranceQuoteResponse.repeatedFields_, null);
};
goog.inherits(proto.insurance_service.GetInsuranceQuoteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.GetInsuranceQuoteResponse.displayName = 'proto.insurance_service.GetInsuranceQuoteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.CreateInsurancePolicyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.CreateInsurancePolicyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.CreateInsurancePolicyRequest.displayName = 'proto.insurance_service.CreateInsurancePolicyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.GetOwnInsurancePoliciesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.GetOwnInsurancePoliciesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.GetOwnInsurancePoliciesRequest.displayName = 'proto.insurance_service.GetOwnInsurancePoliciesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.GetInsurancePoliciesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.insurance_service.GetInsurancePoliciesResponse.repeatedFields_, null);
};
goog.inherits(proto.insurance_service.GetInsurancePoliciesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.GetInsurancePoliciesResponse.displayName = 'proto.insurance_service.GetInsurancePoliciesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.GetInsurancePolicyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.GetInsurancePolicyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.GetInsurancePolicyRequest.displayName = 'proto.insurance_service.GetInsurancePolicyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.GetInsurancePolicyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.GetInsurancePolicyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.GetInsurancePolicyResponse.displayName = 'proto.insurance_service.GetInsurancePolicyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.QuoteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.insurance_service.QuoteResponse.repeatedFields_, null);
};
goog.inherits(proto.insurance_service.QuoteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.QuoteResponse.displayName = 'proto.insurance_service.QuoteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.InsuranceSplitLimits = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.InsuranceSplitLimits, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.InsuranceSplitLimits.displayName = 'proto.insurance_service.InsuranceSplitLimits';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.CreatePolicyChangeQuoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.insurance_service.CreatePolicyChangeQuoteRequest.repeatedFields_, null);
};
goog.inherits(proto.insurance_service.CreatePolicyChangeQuoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.CreatePolicyChangeQuoteRequest.displayName = 'proto.insurance_service.CreatePolicyChangeQuoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.insurance_service.CreatePolicyChangeQuoteResponse.repeatedFields_, null);
};
goog.inherits(proto.insurance_service.CreatePolicyChangeQuoteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.CreatePolicyChangeQuoteResponse.displayName = 'proto.insurance_service.CreatePolicyChangeQuoteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.CreatePolicyChangeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.CreatePolicyChangeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.CreatePolicyChangeRequest.displayName = 'proto.insurance_service.CreatePolicyChangeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.CreatePolicyChangeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.insurance_service.CreatePolicyChangeResponse.repeatedFields_, null);
};
goog.inherits(proto.insurance_service.CreatePolicyChangeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.CreatePolicyChangeResponse.displayName = 'proto.insurance_service.CreatePolicyChangeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.QualifyForLibertyInsuranceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.QualifyForLibertyInsuranceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.QualifyForLibertyInsuranceRequest.displayName = 'proto.insurance_service.QualifyForLibertyInsuranceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.QualifyForLibertyInsuranceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.QualifyForLibertyInsuranceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.QualifyForLibertyInsuranceResponse.displayName = 'proto.insurance_service.QualifyForLibertyInsuranceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.GetInsuredDriversRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.GetInsuredDriversRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.GetInsuredDriversRequest.displayName = 'proto.insurance_service.GetInsuredDriversRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.GetInsuredDriversResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.insurance_service.GetInsuredDriversResponse.repeatedFields_, null);
};
goog.inherits(proto.insurance_service.GetInsuredDriversResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.GetInsuredDriversResponse.displayName = 'proto.insurance_service.GetInsuredDriversResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.CancelInsurancePolicyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.CancelInsurancePolicyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.CancelInsurancePolicyRequest.displayName = 'proto.insurance_service.CancelInsurancePolicyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.CancelInsurancePolicyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.CancelInsurancePolicyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.CancelInsurancePolicyResponse.displayName = 'proto.insurance_service.CancelInsurancePolicyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.ExtendInsurancePolicyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.ExtendInsurancePolicyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.ExtendInsurancePolicyRequest.displayName = 'proto.insurance_service.ExtendInsurancePolicyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.ExtendInsurancePolicyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.ExtendInsurancePolicyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.ExtendInsurancePolicyResponse.displayName = 'proto.insurance_service.ExtendInsurancePolicyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.GetUpdatedInsurancePolicyCardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.GetUpdatedInsurancePolicyCardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.GetUpdatedInsurancePolicyCardRequest.displayName = 'proto.insurance_service.GetUpdatedInsurancePolicyCardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.GetUpdatedInsurancePolicyCardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.GetUpdatedInsurancePolicyCardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.GetUpdatedInsurancePolicyCardResponse.displayName = 'proto.insurance_service.GetUpdatedInsurancePolicyCardResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.GetInsuranceStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.GetInsuranceStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.GetInsuranceStatusRequest.displayName = 'proto.insurance_service.GetInsuranceStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.GetInsuranceStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.GetInsuranceStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.GetInsuranceStatusResponse.displayName = 'proto.insurance_service.GetInsuranceStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.BulkUpdatePoliciesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.BulkUpdatePoliciesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.BulkUpdatePoliciesRequest.displayName = 'proto.insurance_service.BulkUpdatePoliciesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.BulkUpdatePoliciesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.BulkUpdatePoliciesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.BulkUpdatePoliciesResponse.displayName = 'proto.insurance_service.BulkUpdatePoliciesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.GetExternalPoliciesFromDsotRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.GetExternalPoliciesFromDsotRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.GetExternalPoliciesFromDsotRequest.displayName = 'proto.insurance_service.GetExternalPoliciesFromDsotRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.GetExternalPoliciesFromDsotResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.GetExternalPoliciesFromDsotResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.GetExternalPoliciesFromDsotResponse.displayName = 'proto.insurance_service.GetExternalPoliciesFromDsotResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.ImportInsuranceDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.ImportInsuranceDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.ImportInsuranceDataRequest.displayName = 'proto.insurance_service.ImportInsuranceDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.ImportInsuranceDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.ImportInsuranceDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.ImportInsuranceDataResponse.displayName = 'proto.insurance_service.ImportInsuranceDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.IsQualifiedForLiberty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.IsQualifiedForLiberty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.IsQualifiedForLiberty.displayName = 'proto.insurance_service.IsQualifiedForLiberty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.InsurancePolicyCreated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.insurance_service.InsurancePolicyCreated.repeatedFields_, null);
};
goog.inherits(proto.insurance_service.InsurancePolicyCreated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.InsurancePolicyCreated.displayName = 'proto.insurance_service.InsurancePolicyCreated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.InsurancePolicyUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.InsurancePolicyUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.InsurancePolicyUpdated.displayName = 'proto.insurance_service.InsurancePolicyUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.insurance_service.InsurancePolicyCancelled = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.insurance_service.InsurancePolicyCancelled, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.insurance_service.InsurancePolicyCancelled.displayName = 'proto.insurance_service.InsurancePolicyCancelled';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.CustomerIdentity.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.CustomerIdentity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.CustomerIdentity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.CustomerIdentity.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.CustomerIdentity}
 */
proto.insurance_service.CustomerIdentity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.CustomerIdentity;
  return proto.insurance_service.CustomerIdentity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.CustomerIdentity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.CustomerIdentity}
 */
proto.insurance_service.CustomerIdentity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.CustomerIdentity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.CustomerIdentity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.CustomerIdentity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.CustomerIdentity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.insurance_service.CustomerIdentity.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.CustomerIdentity} returns this
 */
proto.insurance_service.CustomerIdentity.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.insurance_service.UpsertOwnInsurancePoliciesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.UpsertOwnInsurancePoliciesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.UpsertOwnInsurancePoliciesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.UpsertOwnInsurancePoliciesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.UpsertOwnInsurancePoliciesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    insurancePoliciesList: jspb.Message.toObjectList(msg.getInsurancePoliciesList(),
    domain_pb.InsurancePolicy.toObject, includeInstance),
    requestSource: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.UpsertOwnInsurancePoliciesRequest}
 */
proto.insurance_service.UpsertOwnInsurancePoliciesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.UpsertOwnInsurancePoliciesRequest;
  return proto.insurance_service.UpsertOwnInsurancePoliciesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.UpsertOwnInsurancePoliciesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.UpsertOwnInsurancePoliciesRequest}
 */
proto.insurance_service.UpsertOwnInsurancePoliciesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_pb.InsurancePolicy;
      reader.readMessage(value,domain_pb.InsurancePolicy.deserializeBinaryFromReader);
      msg.addInsurancePolicies(value);
      break;
    case 2:
      var value = /** @type {!proto.insurance_service.RequestSource} */ (reader.readEnum());
      msg.setRequestSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.UpsertOwnInsurancePoliciesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.UpsertOwnInsurancePoliciesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.UpsertOwnInsurancePoliciesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.UpsertOwnInsurancePoliciesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInsurancePoliciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      domain_pb.InsurancePolicy.serializeBinaryToWriter
    );
  }
  f = message.getRequestSource();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * repeated domain.InsurancePolicy insurance_policies = 1;
 * @return {!Array<!proto.domain.InsurancePolicy>}
 */
proto.insurance_service.UpsertOwnInsurancePoliciesRequest.prototype.getInsurancePoliciesList = function() {
  return /** @type{!Array<!proto.domain.InsurancePolicy>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.InsurancePolicy, 1));
};


/**
 * @param {!Array<!proto.domain.InsurancePolicy>} value
 * @return {!proto.insurance_service.UpsertOwnInsurancePoliciesRequest} returns this
*/
proto.insurance_service.UpsertOwnInsurancePoliciesRequest.prototype.setInsurancePoliciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.domain.InsurancePolicy=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.InsurancePolicy}
 */
proto.insurance_service.UpsertOwnInsurancePoliciesRequest.prototype.addInsurancePolicies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.domain.InsurancePolicy, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.insurance_service.UpsertOwnInsurancePoliciesRequest} returns this
 */
proto.insurance_service.UpsertOwnInsurancePoliciesRequest.prototype.clearInsurancePoliciesList = function() {
  return this.setInsurancePoliciesList([]);
};


/**
 * optional RequestSource request_source = 2;
 * @return {!proto.insurance_service.RequestSource}
 */
proto.insurance_service.UpsertOwnInsurancePoliciesRequest.prototype.getRequestSource = function() {
  return /** @type {!proto.insurance_service.RequestSource} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.insurance_service.RequestSource} value
 * @return {!proto.insurance_service.UpsertOwnInsurancePoliciesRequest} returns this
 */
proto.insurance_service.UpsertOwnInsurancePoliciesRequest.prototype.setRequestSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.insurance_service.UpsertInsurancePoliciesRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.UpsertInsurancePoliciesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.UpsertInsurancePoliciesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.UpsertInsurancePoliciesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.UpsertInsurancePoliciesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerIdentity: (f = msg.getCustomerIdentity()) && proto.insurance_service.CustomerIdentity.toObject(includeInstance, f),
    insurancePoliciesList: jspb.Message.toObjectList(msg.getInsurancePoliciesList(),
    domain_pb.InsurancePolicy.toObject, includeInstance),
    requestSource: jspb.Message.getFieldWithDefault(msg, 3, 0),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.UpsertInsurancePoliciesRequest}
 */
proto.insurance_service.UpsertInsurancePoliciesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.UpsertInsurancePoliciesRequest;
  return proto.insurance_service.UpsertInsurancePoliciesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.UpsertInsurancePoliciesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.UpsertInsurancePoliciesRequest}
 */
proto.insurance_service.UpsertInsurancePoliciesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.insurance_service.CustomerIdentity;
      reader.readMessage(value,proto.insurance_service.CustomerIdentity.deserializeBinaryFromReader);
      msg.setCustomerIdentity(value);
      break;
    case 2:
      var value = new domain_pb.InsurancePolicy;
      reader.readMessage(value,domain_pb.InsurancePolicy.deserializeBinaryFromReader);
      msg.addInsurancePolicies(value);
      break;
    case 3:
      var value = /** @type {!proto.insurance_service.RequestSource} */ (reader.readEnum());
      msg.setRequestSource(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.UpsertInsurancePoliciesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.UpsertInsurancePoliciesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.UpsertInsurancePoliciesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.UpsertInsurancePoliciesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.insurance_service.CustomerIdentity.serializeBinaryToWriter
    );
  }
  f = message.getInsurancePoliciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      domain_pb.InsurancePolicy.serializeBinaryToWriter
    );
  }
  f = message.getRequestSource();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional CustomerIdentity customer_identity = 1;
 * @return {?proto.insurance_service.CustomerIdentity}
 */
proto.insurance_service.UpsertInsurancePoliciesRequest.prototype.getCustomerIdentity = function() {
  return /** @type{?proto.insurance_service.CustomerIdentity} */ (
    jspb.Message.getWrapperField(this, proto.insurance_service.CustomerIdentity, 1));
};


/**
 * @param {?proto.insurance_service.CustomerIdentity|undefined} value
 * @return {!proto.insurance_service.UpsertInsurancePoliciesRequest} returns this
*/
proto.insurance_service.UpsertInsurancePoliciesRequest.prototype.setCustomerIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.insurance_service.UpsertInsurancePoliciesRequest} returns this
 */
proto.insurance_service.UpsertInsurancePoliciesRequest.prototype.clearCustomerIdentity = function() {
  return this.setCustomerIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.insurance_service.UpsertInsurancePoliciesRequest.prototype.hasCustomerIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated domain.InsurancePolicy insurance_policies = 2;
 * @return {!Array<!proto.domain.InsurancePolicy>}
 */
proto.insurance_service.UpsertInsurancePoliciesRequest.prototype.getInsurancePoliciesList = function() {
  return /** @type{!Array<!proto.domain.InsurancePolicy>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.InsurancePolicy, 2));
};


/**
 * @param {!Array<!proto.domain.InsurancePolicy>} value
 * @return {!proto.insurance_service.UpsertInsurancePoliciesRequest} returns this
*/
proto.insurance_service.UpsertInsurancePoliciesRequest.prototype.setInsurancePoliciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.domain.InsurancePolicy=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.InsurancePolicy}
 */
proto.insurance_service.UpsertInsurancePoliciesRequest.prototype.addInsurancePolicies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.domain.InsurancePolicy, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.insurance_service.UpsertInsurancePoliciesRequest} returns this
 */
proto.insurance_service.UpsertInsurancePoliciesRequest.prototype.clearInsurancePoliciesList = function() {
  return this.setInsurancePoliciesList([]);
};


/**
 * optional RequestSource request_source = 3;
 * @return {!proto.insurance_service.RequestSource}
 */
proto.insurance_service.UpsertInsurancePoliciesRequest.prototype.getRequestSource = function() {
  return /** @type {!proto.insurance_service.RequestSource} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.insurance_service.RequestSource} value
 * @return {!proto.insurance_service.UpsertInsurancePoliciesRequest} returns this
 */
proto.insurance_service.UpsertInsurancePoliciesRequest.prototype.setRequestSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string subscription_id = 4;
 * @return {string}
 */
proto.insurance_service.UpsertInsurancePoliciesRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.UpsertInsurancePoliciesRequest} returns this
 */
proto.insurance_service.UpsertInsurancePoliciesRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.UpsertInsurancePoliciesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.UpsertInsurancePoliciesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.UpsertInsurancePoliciesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.UpsertInsurancePoliciesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.UpsertInsurancePoliciesResponse}
 */
proto.insurance_service.UpsertInsurancePoliciesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.UpsertInsurancePoliciesResponse;
  return proto.insurance_service.UpsertInsurancePoliciesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.UpsertInsurancePoliciesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.UpsertInsurancePoliciesResponse}
 */
proto.insurance_service.UpsertInsurancePoliciesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.UpsertInsurancePoliciesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.UpsertInsurancePoliciesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.UpsertInsurancePoliciesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.UpsertInsurancePoliciesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.insurance_service.CreateInsuranceQuoteRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.CreateInsuranceQuoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.CreateInsuranceQuoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.CreateInsuranceQuoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.CreateInsuranceQuoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    additionalDriversList: jspb.Message.toObjectList(msg.getAdditionalDriversList(),
    insurance_pb.AdditionalDriver.toObject, includeInstance),
    deductible: jspb.Message.getFieldWithDefault(msg, 4, 0),
    limits: jspb.Message.getFieldWithDefault(msg, 5, ""),
    programName: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.CreateInsuranceQuoteRequest}
 */
proto.insurance_service.CreateInsuranceQuoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.CreateInsuranceQuoteRequest;
  return proto.insurance_service.CreateInsuranceQuoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.CreateInsuranceQuoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.CreateInsuranceQuoteRequest}
 */
proto.insurance_service.CreateInsuranceQuoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {!proto.insurance_service.CreateInsuranceQuoteRequest.VehicleType} */ (reader.readEnum());
      msg.setVehicleType(value);
      break;
    case 3:
      var value = new insurance_pb.AdditionalDriver;
      reader.readMessage(value,insurance_pb.AdditionalDriver.deserializeBinaryFromReader);
      msg.addAdditionalDrivers(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeductible(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLimits(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.CreateInsuranceQuoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.CreateInsuranceQuoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.CreateInsuranceQuoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.CreateInsuranceQuoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAdditionalDriversList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      insurance_pb.AdditionalDriver.serializeBinaryToWriter
    );
  }
  f = message.getDeductible();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLimits();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProgramName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.insurance_service.CreateInsuranceQuoteRequest.VehicleType = {
  MODEL_3: 0,
  MODEL_Y: 1
};

/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.insurance_service.CreateInsuranceQuoteRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.CreateInsuranceQuoteRequest} returns this
 */
proto.insurance_service.CreateInsuranceQuoteRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional VehicleType vehicle_type = 2;
 * @return {!proto.insurance_service.CreateInsuranceQuoteRequest.VehicleType}
 */
proto.insurance_service.CreateInsuranceQuoteRequest.prototype.getVehicleType = function() {
  return /** @type {!proto.insurance_service.CreateInsuranceQuoteRequest.VehicleType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.insurance_service.CreateInsuranceQuoteRequest.VehicleType} value
 * @return {!proto.insurance_service.CreateInsuranceQuoteRequest} returns this
 */
proto.insurance_service.CreateInsuranceQuoteRequest.prototype.setVehicleType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated insurance.AdditionalDriver additional_drivers = 3;
 * @return {!Array<!proto.insurance.AdditionalDriver>}
 */
proto.insurance_service.CreateInsuranceQuoteRequest.prototype.getAdditionalDriversList = function() {
  return /** @type{!Array<!proto.insurance.AdditionalDriver>} */ (
    jspb.Message.getRepeatedWrapperField(this, insurance_pb.AdditionalDriver, 3));
};


/**
 * @param {!Array<!proto.insurance.AdditionalDriver>} value
 * @return {!proto.insurance_service.CreateInsuranceQuoteRequest} returns this
*/
proto.insurance_service.CreateInsuranceQuoteRequest.prototype.setAdditionalDriversList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.insurance.AdditionalDriver=} opt_value
 * @param {number=} opt_index
 * @return {!proto.insurance.AdditionalDriver}
 */
proto.insurance_service.CreateInsuranceQuoteRequest.prototype.addAdditionalDrivers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.insurance.AdditionalDriver, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.insurance_service.CreateInsuranceQuoteRequest} returns this
 */
proto.insurance_service.CreateInsuranceQuoteRequest.prototype.clearAdditionalDriversList = function() {
  return this.setAdditionalDriversList([]);
};


/**
 * optional int32 deductible = 4;
 * @return {number}
 */
proto.insurance_service.CreateInsuranceQuoteRequest.prototype.getDeductible = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.CreateInsuranceQuoteRequest} returns this
 */
proto.insurance_service.CreateInsuranceQuoteRequest.prototype.setDeductible = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string limits = 5;
 * @return {string}
 */
proto.insurance_service.CreateInsuranceQuoteRequest.prototype.getLimits = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.CreateInsuranceQuoteRequest} returns this
 */
proto.insurance_service.CreateInsuranceQuoteRequest.prototype.setLimits = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string program_name = 6;
 * @return {string}
 */
proto.insurance_service.CreateInsuranceQuoteRequest.prototype.getProgramName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.CreateInsuranceQuoteRequest} returns this
 */
proto.insurance_service.CreateInsuranceQuoteRequest.prototype.setProgramName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.insurance_service.CreateInsuranceQuoteResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.CreateInsuranceQuoteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.CreateInsuranceQuoteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.CreateInsuranceQuoteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.CreateInsuranceQuoteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    quoteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dailyRate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    monthlyRate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    mileageRate: jspb.Message.getFieldWithDefault(msg, 4, 0),
    additionalDriversList: jspb.Message.toObjectList(msg.getAdditionalDriversList(),
    insurance_pb.AdditionalDriver.toObject, includeInstance),
    insuranceSplitLimits: (f = msg.getInsuranceSplitLimits()) && proto.insurance_service.InsuranceSplitLimits.toObject(includeInstance, f),
    deductible: jspb.Message.getFieldWithDefault(msg, 7, 0),
    limits: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.CreateInsuranceQuoteResponse}
 */
proto.insurance_service.CreateInsuranceQuoteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.CreateInsuranceQuoteResponse;
  return proto.insurance_service.CreateInsuranceQuoteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.CreateInsuranceQuoteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.CreateInsuranceQuoteResponse}
 */
proto.insurance_service.CreateInsuranceQuoteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuoteId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDailyRate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthlyRate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMileageRate(value);
      break;
    case 5:
      var value = new insurance_pb.AdditionalDriver;
      reader.readMessage(value,insurance_pb.AdditionalDriver.deserializeBinaryFromReader);
      msg.addAdditionalDrivers(value);
      break;
    case 6:
      var value = new proto.insurance_service.InsuranceSplitLimits;
      reader.readMessage(value,proto.insurance_service.InsuranceSplitLimits.deserializeBinaryFromReader);
      msg.setInsuranceSplitLimits(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeductible(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLimits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.CreateInsuranceQuoteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.CreateInsuranceQuoteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.CreateInsuranceQuoteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.CreateInsuranceQuoteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuoteId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDailyRate();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMonthlyRate();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMileageRate();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAdditionalDriversList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      insurance_pb.AdditionalDriver.serializeBinaryToWriter
    );
  }
  f = message.getInsuranceSplitLimits();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.insurance_service.InsuranceSplitLimits.serializeBinaryToWriter
    );
  }
  f = message.getDeductible();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getLimits();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int32 quote_id = 1;
 * @return {number}
 */
proto.insurance_service.CreateInsuranceQuoteResponse.prototype.getQuoteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.CreateInsuranceQuoteResponse} returns this
 */
proto.insurance_service.CreateInsuranceQuoteResponse.prototype.setQuoteId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 daily_rate = 2;
 * @return {number}
 */
proto.insurance_service.CreateInsuranceQuoteResponse.prototype.getDailyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.CreateInsuranceQuoteResponse} returns this
 */
proto.insurance_service.CreateInsuranceQuoteResponse.prototype.setDailyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 monthly_rate = 3;
 * @return {number}
 */
proto.insurance_service.CreateInsuranceQuoteResponse.prototype.getMonthlyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.CreateInsuranceQuoteResponse} returns this
 */
proto.insurance_service.CreateInsuranceQuoteResponse.prototype.setMonthlyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 mileage_rate = 4;
 * @return {number}
 */
proto.insurance_service.CreateInsuranceQuoteResponse.prototype.getMileageRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.CreateInsuranceQuoteResponse} returns this
 */
proto.insurance_service.CreateInsuranceQuoteResponse.prototype.setMileageRate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated insurance.AdditionalDriver additional_drivers = 5;
 * @return {!Array<!proto.insurance.AdditionalDriver>}
 */
proto.insurance_service.CreateInsuranceQuoteResponse.prototype.getAdditionalDriversList = function() {
  return /** @type{!Array<!proto.insurance.AdditionalDriver>} */ (
    jspb.Message.getRepeatedWrapperField(this, insurance_pb.AdditionalDriver, 5));
};


/**
 * @param {!Array<!proto.insurance.AdditionalDriver>} value
 * @return {!proto.insurance_service.CreateInsuranceQuoteResponse} returns this
*/
proto.insurance_service.CreateInsuranceQuoteResponse.prototype.setAdditionalDriversList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.insurance.AdditionalDriver=} opt_value
 * @param {number=} opt_index
 * @return {!proto.insurance.AdditionalDriver}
 */
proto.insurance_service.CreateInsuranceQuoteResponse.prototype.addAdditionalDrivers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.insurance.AdditionalDriver, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.insurance_service.CreateInsuranceQuoteResponse} returns this
 */
proto.insurance_service.CreateInsuranceQuoteResponse.prototype.clearAdditionalDriversList = function() {
  return this.setAdditionalDriversList([]);
};


/**
 * optional InsuranceSplitLimits insurance_split_limits = 6;
 * @return {?proto.insurance_service.InsuranceSplitLimits}
 */
proto.insurance_service.CreateInsuranceQuoteResponse.prototype.getInsuranceSplitLimits = function() {
  return /** @type{?proto.insurance_service.InsuranceSplitLimits} */ (
    jspb.Message.getWrapperField(this, proto.insurance_service.InsuranceSplitLimits, 6));
};


/**
 * @param {?proto.insurance_service.InsuranceSplitLimits|undefined} value
 * @return {!proto.insurance_service.CreateInsuranceQuoteResponse} returns this
*/
proto.insurance_service.CreateInsuranceQuoteResponse.prototype.setInsuranceSplitLimits = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.insurance_service.CreateInsuranceQuoteResponse} returns this
 */
proto.insurance_service.CreateInsuranceQuoteResponse.prototype.clearInsuranceSplitLimits = function() {
  return this.setInsuranceSplitLimits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.insurance_service.CreateInsuranceQuoteResponse.prototype.hasInsuranceSplitLimits = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 deductible = 7;
 * @return {number}
 */
proto.insurance_service.CreateInsuranceQuoteResponse.prototype.getDeductible = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.CreateInsuranceQuoteResponse} returns this
 */
proto.insurance_service.CreateInsuranceQuoteResponse.prototype.setDeductible = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string limits = 8;
 * @return {string}
 */
proto.insurance_service.CreateInsuranceQuoteResponse.prototype.getLimits = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.CreateInsuranceQuoteResponse} returns this
 */
proto.insurance_service.CreateInsuranceQuoteResponse.prototype.setLimits = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.insurance_service.CreateAllInsuranceQuotesRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.CreateAllInsuranceQuotesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.CreateAllInsuranceQuotesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.CreateAllInsuranceQuotesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.CreateAllInsuranceQuotesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    additionalDriversList: jspb.Message.toObjectList(msg.getAdditionalDriversList(),
    insurance_pb.AdditionalDriver.toObject, includeInstance),
    programName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.CreateAllInsuranceQuotesRequest}
 */
proto.insurance_service.CreateAllInsuranceQuotesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.CreateAllInsuranceQuotesRequest;
  return proto.insurance_service.CreateAllInsuranceQuotesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.CreateAllInsuranceQuotesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.CreateAllInsuranceQuotesRequest}
 */
proto.insurance_service.CreateAllInsuranceQuotesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {!proto.insurance_service.CreateAllInsuranceQuotesRequest.VehicleType} */ (reader.readEnum());
      msg.setVehicleType(value);
      break;
    case 3:
      var value = new insurance_pb.AdditionalDriver;
      reader.readMessage(value,insurance_pb.AdditionalDriver.deserializeBinaryFromReader);
      msg.addAdditionalDrivers(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.CreateAllInsuranceQuotesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.CreateAllInsuranceQuotesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.CreateAllInsuranceQuotesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.CreateAllInsuranceQuotesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAdditionalDriversList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      insurance_pb.AdditionalDriver.serializeBinaryToWriter
    );
  }
  f = message.getProgramName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.insurance_service.CreateAllInsuranceQuotesRequest.VehicleType = {
  MODEL_3: 0,
  MODEL_Y: 1
};

/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.insurance_service.CreateAllInsuranceQuotesRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.CreateAllInsuranceQuotesRequest} returns this
 */
proto.insurance_service.CreateAllInsuranceQuotesRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional VehicleType vehicle_type = 2;
 * @return {!proto.insurance_service.CreateAllInsuranceQuotesRequest.VehicleType}
 */
proto.insurance_service.CreateAllInsuranceQuotesRequest.prototype.getVehicleType = function() {
  return /** @type {!proto.insurance_service.CreateAllInsuranceQuotesRequest.VehicleType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.insurance_service.CreateAllInsuranceQuotesRequest.VehicleType} value
 * @return {!proto.insurance_service.CreateAllInsuranceQuotesRequest} returns this
 */
proto.insurance_service.CreateAllInsuranceQuotesRequest.prototype.setVehicleType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated insurance.AdditionalDriver additional_drivers = 3;
 * @return {!Array<!proto.insurance.AdditionalDriver>}
 */
proto.insurance_service.CreateAllInsuranceQuotesRequest.prototype.getAdditionalDriversList = function() {
  return /** @type{!Array<!proto.insurance.AdditionalDriver>} */ (
    jspb.Message.getRepeatedWrapperField(this, insurance_pb.AdditionalDriver, 3));
};


/**
 * @param {!Array<!proto.insurance.AdditionalDriver>} value
 * @return {!proto.insurance_service.CreateAllInsuranceQuotesRequest} returns this
*/
proto.insurance_service.CreateAllInsuranceQuotesRequest.prototype.setAdditionalDriversList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.insurance.AdditionalDriver=} opt_value
 * @param {number=} opt_index
 * @return {!proto.insurance.AdditionalDriver}
 */
proto.insurance_service.CreateAllInsuranceQuotesRequest.prototype.addAdditionalDrivers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.insurance.AdditionalDriver, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.insurance_service.CreateAllInsuranceQuotesRequest} returns this
 */
proto.insurance_service.CreateAllInsuranceQuotesRequest.prototype.clearAdditionalDriversList = function() {
  return this.setAdditionalDriversList([]);
};


/**
 * optional string program_name = 4;
 * @return {string}
 */
proto.insurance_service.CreateAllInsuranceQuotesRequest.prototype.getProgramName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.CreateAllInsuranceQuotesRequest} returns this
 */
proto.insurance_service.CreateAllInsuranceQuotesRequest.prototype.setProgramName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string subscription_id = 5;
 * @return {string}
 */
proto.insurance_service.CreateAllInsuranceQuotesRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.CreateAllInsuranceQuotesRequest} returns this
 */
proto.insurance_service.CreateAllInsuranceQuotesRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.insurance_service.CreateAllInsuranceQuotesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.CreateAllInsuranceQuotesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.CreateAllInsuranceQuotesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.CreateAllInsuranceQuotesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.CreateAllInsuranceQuotesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    quoteresponseList: jspb.Message.toObjectList(msg.getQuoteresponseList(),
    proto.insurance_service.QuoteResponse.toObject, includeInstance),
    isQualified: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.CreateAllInsuranceQuotesResponse}
 */
proto.insurance_service.CreateAllInsuranceQuotesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.CreateAllInsuranceQuotesResponse;
  return proto.insurance_service.CreateAllInsuranceQuotesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.CreateAllInsuranceQuotesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.CreateAllInsuranceQuotesResponse}
 */
proto.insurance_service.CreateAllInsuranceQuotesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.insurance_service.QuoteResponse;
      reader.readMessage(value,proto.insurance_service.QuoteResponse.deserializeBinaryFromReader);
      msg.addQuoteresponse(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsQualified(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.CreateAllInsuranceQuotesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.CreateAllInsuranceQuotesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.CreateAllInsuranceQuotesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.CreateAllInsuranceQuotesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuoteresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.insurance_service.QuoteResponse.serializeBinaryToWriter
    );
  }
  f = message.getIsQualified();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated QuoteResponse quoteResponse = 1;
 * @return {!Array<!proto.insurance_service.QuoteResponse>}
 */
proto.insurance_service.CreateAllInsuranceQuotesResponse.prototype.getQuoteresponseList = function() {
  return /** @type{!Array<!proto.insurance_service.QuoteResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.insurance_service.QuoteResponse, 1));
};


/**
 * @param {!Array<!proto.insurance_service.QuoteResponse>} value
 * @return {!proto.insurance_service.CreateAllInsuranceQuotesResponse} returns this
*/
proto.insurance_service.CreateAllInsuranceQuotesResponse.prototype.setQuoteresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.insurance_service.QuoteResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.insurance_service.QuoteResponse}
 */
proto.insurance_service.CreateAllInsuranceQuotesResponse.prototype.addQuoteresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.insurance_service.QuoteResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.insurance_service.CreateAllInsuranceQuotesResponse} returns this
 */
proto.insurance_service.CreateAllInsuranceQuotesResponse.prototype.clearQuoteresponseList = function() {
  return this.setQuoteresponseList([]);
};


/**
 * optional bool is_qualified = 2;
 * @return {boolean}
 */
proto.insurance_service.CreateAllInsuranceQuotesResponse.prototype.getIsQualified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.insurance_service.CreateAllInsuranceQuotesResponse} returns this
 */
proto.insurance_service.CreateAllInsuranceQuotesResponse.prototype.setIsQualified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.SelectInsuranceQuoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.SelectInsuranceQuoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.SelectInsuranceQuoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.SelectInsuranceQuoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quoteId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    deprecatedQuoteId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.SelectInsuranceQuoteRequest}
 */
proto.insurance_service.SelectInsuranceQuoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.SelectInsuranceQuoteRequest;
  return proto.insurance_service.SelectInsuranceQuoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.SelectInsuranceQuoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.SelectInsuranceQuoteRequest}
 */
proto.insurance_service.SelectInsuranceQuoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuoteId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeprecatedQuoteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.SelectInsuranceQuoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.SelectInsuranceQuoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.SelectInsuranceQuoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.SelectInsuranceQuoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuoteId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDeprecatedQuoteId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.insurance_service.SelectInsuranceQuoteRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.SelectInsuranceQuoteRequest} returns this
 */
proto.insurance_service.SelectInsuranceQuoteRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string quote_id = 3;
 * @return {string}
 */
proto.insurance_service.SelectInsuranceQuoteRequest.prototype.getQuoteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.SelectInsuranceQuoteRequest} returns this
 */
proto.insurance_service.SelectInsuranceQuoteRequest.prototype.setQuoteId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 deprecated_quote_id = 2;
 * @return {number}
 */
proto.insurance_service.SelectInsuranceQuoteRequest.prototype.getDeprecatedQuoteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.SelectInsuranceQuoteRequest} returns this
 */
proto.insurance_service.SelectInsuranceQuoteRequest.prototype.setDeprecatedQuoteId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.SelectInsuranceQuoteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.SelectInsuranceQuoteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.SelectInsuranceQuoteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.SelectInsuranceQuoteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.SelectInsuranceQuoteResponse}
 */
proto.insurance_service.SelectInsuranceQuoteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.SelectInsuranceQuoteResponse;
  return proto.insurance_service.SelectInsuranceQuoteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.SelectInsuranceQuoteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.SelectInsuranceQuoteResponse}
 */
proto.insurance_service.SelectInsuranceQuoteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.SelectInsuranceQuoteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.SelectInsuranceQuoteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.SelectInsuranceQuoteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.SelectInsuranceQuoteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.GetInsuranceQuoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.GetInsuranceQuoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.GetInsuranceQuoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetInsuranceQuoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.GetInsuranceQuoteRequest}
 */
proto.insurance_service.GetInsuranceQuoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.GetInsuranceQuoteRequest;
  return proto.insurance_service.GetInsuranceQuoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.GetInsuranceQuoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.GetInsuranceQuoteRequest}
 */
proto.insurance_service.GetInsuranceQuoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.GetInsuranceQuoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.GetInsuranceQuoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.GetInsuranceQuoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetInsuranceQuoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.insurance_service.GetInsuranceQuoteRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.GetInsuranceQuoteRequest} returns this
 */
proto.insurance_service.GetInsuranceQuoteRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.insurance_service.GetInsuranceQuoteRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.GetInsuranceQuoteRequest} returns this
 */
proto.insurance_service.GetInsuranceQuoteRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.insurance_service.GetInsuranceQuoteResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.GetInsuranceQuoteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.GetInsuranceQuoteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.GetInsuranceQuoteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetInsuranceQuoteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    monthlyRate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    mileageRate: jspb.Message.getFieldWithDefault(msg, 4, 0),
    additionalDriversList: jspb.Message.toObjectList(msg.getAdditionalDriversList(),
    insurance_pb.AdditionalDriver.toObject, includeInstance),
    insuranceSplitLimits: (f = msg.getInsuranceSplitLimits()) && proto.insurance_service.InsuranceSplitLimits.toObject(includeInstance, f),
    isQualified: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    deductible: jspb.Message.getFieldWithDefault(msg, 8, 0),
    limits: jspb.Message.getFieldWithDefault(msg, 9, ""),
    quoteId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    deprecatedQuoteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dailyRate: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.GetInsuranceQuoteResponse}
 */
proto.insurance_service.GetInsuranceQuoteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.GetInsuranceQuoteResponse;
  return proto.insurance_service.GetInsuranceQuoteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.GetInsuranceQuoteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.GetInsuranceQuoteResponse}
 */
proto.insurance_service.GetInsuranceQuoteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthlyRate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMileageRate(value);
      break;
    case 5:
      var value = new insurance_pb.AdditionalDriver;
      reader.readMessage(value,insurance_pb.AdditionalDriver.deserializeBinaryFromReader);
      msg.addAdditionalDrivers(value);
      break;
    case 6:
      var value = new proto.insurance_service.InsuranceSplitLimits;
      reader.readMessage(value,proto.insurance_service.InsuranceSplitLimits.deserializeBinaryFromReader);
      msg.setInsuranceSplitLimits(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsQualified(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeductible(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLimits(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuoteId(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeprecatedQuoteId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDailyRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.GetInsuranceQuoteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.GetInsuranceQuoteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.GetInsuranceQuoteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetInsuranceQuoteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMonthlyRate();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMileageRate();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAdditionalDriversList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      insurance_pb.AdditionalDriver.serializeBinaryToWriter
    );
  }
  f = message.getInsuranceSplitLimits();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.insurance_service.InsuranceSplitLimits.serializeBinaryToWriter
    );
  }
  f = message.getIsQualified();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getDeductible();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getLimits();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getQuoteId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDeprecatedQuoteId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDailyRate();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 monthly_rate = 3;
 * @return {number}
 */
proto.insurance_service.GetInsuranceQuoteResponse.prototype.getMonthlyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.GetInsuranceQuoteResponse} returns this
 */
proto.insurance_service.GetInsuranceQuoteResponse.prototype.setMonthlyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 mileage_rate = 4;
 * @return {number}
 */
proto.insurance_service.GetInsuranceQuoteResponse.prototype.getMileageRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.GetInsuranceQuoteResponse} returns this
 */
proto.insurance_service.GetInsuranceQuoteResponse.prototype.setMileageRate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated insurance.AdditionalDriver additional_drivers = 5;
 * @return {!Array<!proto.insurance.AdditionalDriver>}
 */
proto.insurance_service.GetInsuranceQuoteResponse.prototype.getAdditionalDriversList = function() {
  return /** @type{!Array<!proto.insurance.AdditionalDriver>} */ (
    jspb.Message.getRepeatedWrapperField(this, insurance_pb.AdditionalDriver, 5));
};


/**
 * @param {!Array<!proto.insurance.AdditionalDriver>} value
 * @return {!proto.insurance_service.GetInsuranceQuoteResponse} returns this
*/
proto.insurance_service.GetInsuranceQuoteResponse.prototype.setAdditionalDriversList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.insurance.AdditionalDriver=} opt_value
 * @param {number=} opt_index
 * @return {!proto.insurance.AdditionalDriver}
 */
proto.insurance_service.GetInsuranceQuoteResponse.prototype.addAdditionalDrivers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.insurance.AdditionalDriver, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.insurance_service.GetInsuranceQuoteResponse} returns this
 */
proto.insurance_service.GetInsuranceQuoteResponse.prototype.clearAdditionalDriversList = function() {
  return this.setAdditionalDriversList([]);
};


/**
 * optional InsuranceSplitLimits insurance_split_limits = 6;
 * @return {?proto.insurance_service.InsuranceSplitLimits}
 */
proto.insurance_service.GetInsuranceQuoteResponse.prototype.getInsuranceSplitLimits = function() {
  return /** @type{?proto.insurance_service.InsuranceSplitLimits} */ (
    jspb.Message.getWrapperField(this, proto.insurance_service.InsuranceSplitLimits, 6));
};


/**
 * @param {?proto.insurance_service.InsuranceSplitLimits|undefined} value
 * @return {!proto.insurance_service.GetInsuranceQuoteResponse} returns this
*/
proto.insurance_service.GetInsuranceQuoteResponse.prototype.setInsuranceSplitLimits = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.insurance_service.GetInsuranceQuoteResponse} returns this
 */
proto.insurance_service.GetInsuranceQuoteResponse.prototype.clearInsuranceSplitLimits = function() {
  return this.setInsuranceSplitLimits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.insurance_service.GetInsuranceQuoteResponse.prototype.hasInsuranceSplitLimits = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool is_qualified = 7;
 * @return {boolean}
 */
proto.insurance_service.GetInsuranceQuoteResponse.prototype.getIsQualified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.insurance_service.GetInsuranceQuoteResponse} returns this
 */
proto.insurance_service.GetInsuranceQuoteResponse.prototype.setIsQualified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int32 deductible = 8;
 * @return {number}
 */
proto.insurance_service.GetInsuranceQuoteResponse.prototype.getDeductible = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.GetInsuranceQuoteResponse} returns this
 */
proto.insurance_service.GetInsuranceQuoteResponse.prototype.setDeductible = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string limits = 9;
 * @return {string}
 */
proto.insurance_service.GetInsuranceQuoteResponse.prototype.getLimits = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.GetInsuranceQuoteResponse} returns this
 */
proto.insurance_service.GetInsuranceQuoteResponse.prototype.setLimits = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string quote_id = 10;
 * @return {string}
 */
proto.insurance_service.GetInsuranceQuoteResponse.prototype.getQuoteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.GetInsuranceQuoteResponse} returns this
 */
proto.insurance_service.GetInsuranceQuoteResponse.prototype.setQuoteId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 deprecated_quote_id = 1;
 * @return {number}
 */
proto.insurance_service.GetInsuranceQuoteResponse.prototype.getDeprecatedQuoteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.GetInsuranceQuoteResponse} returns this
 */
proto.insurance_service.GetInsuranceQuoteResponse.prototype.setDeprecatedQuoteId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 daily_rate = 2;
 * @return {number}
 */
proto.insurance_service.GetInsuranceQuoteResponse.prototype.getDailyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.GetInsuranceQuoteResponse} returns this
 */
proto.insurance_service.GetInsuranceQuoteResponse.prototype.setDailyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.CreateInsurancePolicyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.CreateInsurancePolicyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.CreateInsurancePolicyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.CreateInsurancePolicyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quoteId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.CreateInsurancePolicyRequest}
 */
proto.insurance_service.CreateInsurancePolicyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.CreateInsurancePolicyRequest;
  return proto.insurance_service.CreateInsurancePolicyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.CreateInsurancePolicyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.CreateInsurancePolicyRequest}
 */
proto.insurance_service.CreateInsurancePolicyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuoteId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.CreateInsurancePolicyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.CreateInsurancePolicyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.CreateInsurancePolicyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.CreateInsurancePolicyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuoteId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.insurance_service.CreateInsurancePolicyRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.CreateInsurancePolicyRequest} returns this
 */
proto.insurance_service.CreateInsurancePolicyRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 quote_id = 2;
 * @return {number}
 */
proto.insurance_service.CreateInsurancePolicyRequest.prototype.getQuoteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.CreateInsurancePolicyRequest} returns this
 */
proto.insurance_service.CreateInsurancePolicyRequest.prototype.setQuoteId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string subscription_id = 3;
 * @return {string}
 */
proto.insurance_service.CreateInsurancePolicyRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.CreateInsurancePolicyRequest} returns this
 */
proto.insurance_service.CreateInsurancePolicyRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.GetOwnInsurancePoliciesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.GetOwnInsurancePoliciesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.GetOwnInsurancePoliciesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetOwnInsurancePoliciesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.GetOwnInsurancePoliciesRequest}
 */
proto.insurance_service.GetOwnInsurancePoliciesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.GetOwnInsurancePoliciesRequest;
  return proto.insurance_service.GetOwnInsurancePoliciesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.GetOwnInsurancePoliciesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.GetOwnInsurancePoliciesRequest}
 */
proto.insurance_service.GetOwnInsurancePoliciesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.GetOwnInsurancePoliciesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.GetOwnInsurancePoliciesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.GetOwnInsurancePoliciesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetOwnInsurancePoliciesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.insurance_service.GetInsurancePoliciesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.GetInsurancePoliciesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.GetInsurancePoliciesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.GetInsurancePoliciesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetInsurancePoliciesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    insurancePoliciesList: jspb.Message.toObjectList(msg.getInsurancePoliciesList(),
    domain_pb.InsurancePolicy.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.GetInsurancePoliciesResponse}
 */
proto.insurance_service.GetInsurancePoliciesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.GetInsurancePoliciesResponse;
  return proto.insurance_service.GetInsurancePoliciesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.GetInsurancePoliciesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.GetInsurancePoliciesResponse}
 */
proto.insurance_service.GetInsurancePoliciesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_pb.InsurancePolicy;
      reader.readMessage(value,domain_pb.InsurancePolicy.deserializeBinaryFromReader);
      msg.addInsurancePolicies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.GetInsurancePoliciesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.GetInsurancePoliciesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.GetInsurancePoliciesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetInsurancePoliciesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInsurancePoliciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      domain_pb.InsurancePolicy.serializeBinaryToWriter
    );
  }
};


/**
 * repeated domain.InsurancePolicy insurance_policies = 1;
 * @return {!Array<!proto.domain.InsurancePolicy>}
 */
proto.insurance_service.GetInsurancePoliciesResponse.prototype.getInsurancePoliciesList = function() {
  return /** @type{!Array<!proto.domain.InsurancePolicy>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.InsurancePolicy, 1));
};


/**
 * @param {!Array<!proto.domain.InsurancePolicy>} value
 * @return {!proto.insurance_service.GetInsurancePoliciesResponse} returns this
*/
proto.insurance_service.GetInsurancePoliciesResponse.prototype.setInsurancePoliciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.domain.InsurancePolicy=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.InsurancePolicy}
 */
proto.insurance_service.GetInsurancePoliciesResponse.prototype.addInsurancePolicies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.domain.InsurancePolicy, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.insurance_service.GetInsurancePoliciesResponse} returns this
 */
proto.insurance_service.GetInsurancePoliciesResponse.prototype.clearInsurancePoliciesList = function() {
  return this.setInsurancePoliciesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.GetInsurancePolicyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.GetInsurancePolicyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.GetInsurancePolicyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetInsurancePolicyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.GetInsurancePolicyRequest}
 */
proto.insurance_service.GetInsurancePolicyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.GetInsurancePolicyRequest;
  return proto.insurance_service.GetInsurancePolicyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.GetInsurancePolicyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.GetInsurancePolicyRequest}
 */
proto.insurance_service.GetInsurancePolicyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.GetInsurancePolicyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.GetInsurancePolicyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.GetInsurancePolicyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetInsurancePolicyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.insurance_service.GetInsurancePolicyRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.GetInsurancePolicyRequest} returns this
 */
proto.insurance_service.GetInsurancePolicyRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.insurance_service.GetInsurancePolicyRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.GetInsurancePolicyRequest} returns this
 */
proto.insurance_service.GetInsurancePolicyRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.GetInsurancePolicyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.GetInsurancePolicyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetInsurancePolicyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    policyNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    effectiveDate: jspb.Message.getFieldWithDefault(msg, 4, 0),
    expirationDate: jspb.Message.getFieldWithDefault(msg, 5, 0),
    dailyRate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    monthlyRate: jspb.Message.getFieldWithDefault(msg, 7, 0),
    excessMileageRate: jspb.Message.getFieldWithDefault(msg, 8, 0),
    insuranceCardDocumentId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    insuranceSplitLimits: (f = msg.getInsuranceSplitLimits()) && proto.insurance_service.InsuranceSplitLimits.toObject(includeInstance, f),
    deductible: jspb.Message.getFieldWithDefault(msg, 11, 0),
    limits: jspb.Message.getFieldWithDefault(msg, 12, ""),
    policyId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    deprecatedPolicyId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.GetInsurancePolicyResponse}
 */
proto.insurance_service.GetInsurancePolicyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.GetInsurancePolicyResponse;
  return proto.insurance_service.GetInsurancePolicyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.GetInsurancePolicyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.GetInsurancePolicyResponse}
 */
proto.insurance_service.GetInsurancePolicyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPolicyNumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEffectiveDate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpirationDate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDailyRate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthlyRate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExcessMileageRate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setInsuranceCardDocumentId(value);
      break;
    case 10:
      var value = new proto.insurance_service.InsuranceSplitLimits;
      reader.readMessage(value,proto.insurance_service.InsuranceSplitLimits.deserializeBinaryFromReader);
      msg.setInsuranceSplitLimits(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeductible(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLimits(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPolicyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeprecatedPolicyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.GetInsurancePolicyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.GetInsurancePolicyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetInsurancePolicyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getPolicyNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEffectiveDate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getExpirationDate();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getDailyRate();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMonthlyRate();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getExcessMileageRate();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getInsuranceCardDocumentId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getInsuranceSplitLimits();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.insurance_service.InsuranceSplitLimits.serializeBinaryToWriter
    );
  }
  f = message.getDeductible();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getLimits();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPolicyId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDeprecatedPolicyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.insurance_service.GetInsurancePolicyResponse} returns this
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string policy_number = 3;
 * @return {string}
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.getPolicyNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.GetInsurancePolicyResponse} returns this
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.setPolicyNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 effective_date = 4;
 * @return {number}
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.getEffectiveDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.GetInsurancePolicyResponse} returns this
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.setEffectiveDate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 expiration_date = 5;
 * @return {number}
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.getExpirationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.GetInsurancePolicyResponse} returns this
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.setExpirationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 daily_rate = 6;
 * @return {number}
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.getDailyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.GetInsurancePolicyResponse} returns this
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.setDailyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 monthly_rate = 7;
 * @return {number}
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.getMonthlyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.GetInsurancePolicyResponse} returns this
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.setMonthlyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 excess_mileage_rate = 8;
 * @return {number}
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.getExcessMileageRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.GetInsurancePolicyResponse} returns this
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.setExcessMileageRate = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string insurance_card_document_id = 9;
 * @return {string}
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.getInsuranceCardDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.GetInsurancePolicyResponse} returns this
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.setInsuranceCardDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional InsuranceSplitLimits insurance_split_limits = 10;
 * @return {?proto.insurance_service.InsuranceSplitLimits}
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.getInsuranceSplitLimits = function() {
  return /** @type{?proto.insurance_service.InsuranceSplitLimits} */ (
    jspb.Message.getWrapperField(this, proto.insurance_service.InsuranceSplitLimits, 10));
};


/**
 * @param {?proto.insurance_service.InsuranceSplitLimits|undefined} value
 * @return {!proto.insurance_service.GetInsurancePolicyResponse} returns this
*/
proto.insurance_service.GetInsurancePolicyResponse.prototype.setInsuranceSplitLimits = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.insurance_service.GetInsurancePolicyResponse} returns this
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.clearInsuranceSplitLimits = function() {
  return this.setInsuranceSplitLimits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.hasInsuranceSplitLimits = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int32 deductible = 11;
 * @return {number}
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.getDeductible = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.GetInsurancePolicyResponse} returns this
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.setDeductible = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string limits = 12;
 * @return {string}
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.getLimits = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.GetInsurancePolicyResponse} returns this
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.setLimits = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string policy_id = 13;
 * @return {string}
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.getPolicyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.GetInsurancePolicyResponse} returns this
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.setPolicyId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int32 deprecated_policy_id = 2;
 * @return {number}
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.getDeprecatedPolicyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.GetInsurancePolicyResponse} returns this
 */
proto.insurance_service.GetInsurancePolicyResponse.prototype.setDeprecatedPolicyId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.insurance_service.QuoteResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.QuoteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.QuoteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.QuoteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.QuoteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dailyRate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    monthlyRate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    mileageRate: jspb.Message.getFieldWithDefault(msg, 4, 0),
    additionalDriversList: jspb.Message.toObjectList(msg.getAdditionalDriversList(),
    insurance_pb.AdditionalDriver.toObject, includeInstance),
    insuranceSplitLimits: (f = msg.getInsuranceSplitLimits()) && proto.insurance_service.InsuranceSplitLimits.toObject(includeInstance, f),
    deductible: jspb.Message.getFieldWithDefault(msg, 7, 0),
    limits: jspb.Message.getFieldWithDefault(msg, 8, ""),
    quoteId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    deprecatedQuoteId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.QuoteResponse}
 */
proto.insurance_service.QuoteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.QuoteResponse;
  return proto.insurance_service.QuoteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.QuoteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.QuoteResponse}
 */
proto.insurance_service.QuoteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDailyRate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthlyRate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMileageRate(value);
      break;
    case 5:
      var value = new insurance_pb.AdditionalDriver;
      reader.readMessage(value,insurance_pb.AdditionalDriver.deserializeBinaryFromReader);
      msg.addAdditionalDrivers(value);
      break;
    case 6:
      var value = new proto.insurance_service.InsuranceSplitLimits;
      reader.readMessage(value,proto.insurance_service.InsuranceSplitLimits.deserializeBinaryFromReader);
      msg.setInsuranceSplitLimits(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeductible(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLimits(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuoteId(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeprecatedQuoteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.QuoteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.QuoteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.QuoteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.QuoteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDailyRate();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMonthlyRate();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMileageRate();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAdditionalDriversList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      insurance_pb.AdditionalDriver.serializeBinaryToWriter
    );
  }
  f = message.getInsuranceSplitLimits();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.insurance_service.InsuranceSplitLimits.serializeBinaryToWriter
    );
  }
  f = message.getDeductible();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getLimits();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getQuoteId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDeprecatedQuoteId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 daily_rate = 2;
 * @return {number}
 */
proto.insurance_service.QuoteResponse.prototype.getDailyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.QuoteResponse} returns this
 */
proto.insurance_service.QuoteResponse.prototype.setDailyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 monthly_rate = 3;
 * @return {number}
 */
proto.insurance_service.QuoteResponse.prototype.getMonthlyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.QuoteResponse} returns this
 */
proto.insurance_service.QuoteResponse.prototype.setMonthlyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 mileage_rate = 4;
 * @return {number}
 */
proto.insurance_service.QuoteResponse.prototype.getMileageRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.QuoteResponse} returns this
 */
proto.insurance_service.QuoteResponse.prototype.setMileageRate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated insurance.AdditionalDriver additional_drivers = 5;
 * @return {!Array<!proto.insurance.AdditionalDriver>}
 */
proto.insurance_service.QuoteResponse.prototype.getAdditionalDriversList = function() {
  return /** @type{!Array<!proto.insurance.AdditionalDriver>} */ (
    jspb.Message.getRepeatedWrapperField(this, insurance_pb.AdditionalDriver, 5));
};


/**
 * @param {!Array<!proto.insurance.AdditionalDriver>} value
 * @return {!proto.insurance_service.QuoteResponse} returns this
*/
proto.insurance_service.QuoteResponse.prototype.setAdditionalDriversList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.insurance.AdditionalDriver=} opt_value
 * @param {number=} opt_index
 * @return {!proto.insurance.AdditionalDriver}
 */
proto.insurance_service.QuoteResponse.prototype.addAdditionalDrivers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.insurance.AdditionalDriver, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.insurance_service.QuoteResponse} returns this
 */
proto.insurance_service.QuoteResponse.prototype.clearAdditionalDriversList = function() {
  return this.setAdditionalDriversList([]);
};


/**
 * optional InsuranceSplitLimits insurance_split_limits = 6;
 * @return {?proto.insurance_service.InsuranceSplitLimits}
 */
proto.insurance_service.QuoteResponse.prototype.getInsuranceSplitLimits = function() {
  return /** @type{?proto.insurance_service.InsuranceSplitLimits} */ (
    jspb.Message.getWrapperField(this, proto.insurance_service.InsuranceSplitLimits, 6));
};


/**
 * @param {?proto.insurance_service.InsuranceSplitLimits|undefined} value
 * @return {!proto.insurance_service.QuoteResponse} returns this
*/
proto.insurance_service.QuoteResponse.prototype.setInsuranceSplitLimits = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.insurance_service.QuoteResponse} returns this
 */
proto.insurance_service.QuoteResponse.prototype.clearInsuranceSplitLimits = function() {
  return this.setInsuranceSplitLimits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.insurance_service.QuoteResponse.prototype.hasInsuranceSplitLimits = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 deductible = 7;
 * @return {number}
 */
proto.insurance_service.QuoteResponse.prototype.getDeductible = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.QuoteResponse} returns this
 */
proto.insurance_service.QuoteResponse.prototype.setDeductible = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string limits = 8;
 * @return {string}
 */
proto.insurance_service.QuoteResponse.prototype.getLimits = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.QuoteResponse} returns this
 */
proto.insurance_service.QuoteResponse.prototype.setLimits = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string quote_id = 9;
 * @return {string}
 */
proto.insurance_service.QuoteResponse.prototype.getQuoteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.QuoteResponse} returns this
 */
proto.insurance_service.QuoteResponse.prototype.setQuoteId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 deprecated_quote_id = 1;
 * @return {number}
 */
proto.insurance_service.QuoteResponse.prototype.getDeprecatedQuoteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.QuoteResponse} returns this
 */
proto.insurance_service.QuoteResponse.prototype.setDeprecatedQuoteId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.InsuranceSplitLimits.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.InsuranceSplitLimits.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.InsuranceSplitLimits} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.InsuranceSplitLimits.toObject = function(includeInstance, msg) {
  var f, obj = {
    liabilityPerPerson: jspb.Message.getFieldWithDefault(msg, 1, 0),
    liabilityPerAccident: jspb.Message.getFieldWithDefault(msg, 2, 0),
    liabilityPropertyDamage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    uninsuredPerPerson: jspb.Message.getFieldWithDefault(msg, 4, 0),
    uninsuredPerAccident: jspb.Message.getFieldWithDefault(msg, 5, 0),
    medicalPayments: jspb.Message.getFieldWithDefault(msg, 7, 0),
    comprehensiveDeductible: jspb.Message.getFieldWithDefault(msg, 8, 0),
    collisionDeductible: jspb.Message.getFieldWithDefault(msg, 9, 0),
    rentalReimbursement: jspb.Message.getFieldWithDefault(msg, 10, 0),
    rentalReimbursementMaxDays: jspb.Message.getFieldWithDefault(msg, 11, 0),
    towingAndLabor: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.InsuranceSplitLimits}
 */
proto.insurance_service.InsuranceSplitLimits.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.InsuranceSplitLimits;
  return proto.insurance_service.InsuranceSplitLimits.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.InsuranceSplitLimits} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.InsuranceSplitLimits}
 */
proto.insurance_service.InsuranceSplitLimits.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLiabilityPerPerson(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLiabilityPerAccident(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLiabilityPropertyDamage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUninsuredPerPerson(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUninsuredPerAccident(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMedicalPayments(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setComprehensiveDeductible(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCollisionDeductible(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRentalReimbursement(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRentalReimbursementMaxDays(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTowingAndLabor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.InsuranceSplitLimits.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.InsuranceSplitLimits.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.InsuranceSplitLimits} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.InsuranceSplitLimits.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiabilityPerPerson();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLiabilityPerAccident();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLiabilityPropertyDamage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getUninsuredPerPerson();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getUninsuredPerAccident();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMedicalPayments();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getComprehensiveDeductible();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getCollisionDeductible();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getRentalReimbursement();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getRentalReimbursementMaxDays();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getTowingAndLabor();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
};


/**
 * optional int32 liability_per_person = 1;
 * @return {number}
 */
proto.insurance_service.InsuranceSplitLimits.prototype.getLiabilityPerPerson = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.InsuranceSplitLimits} returns this
 */
proto.insurance_service.InsuranceSplitLimits.prototype.setLiabilityPerPerson = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 liability_per_accident = 2;
 * @return {number}
 */
proto.insurance_service.InsuranceSplitLimits.prototype.getLiabilityPerAccident = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.InsuranceSplitLimits} returns this
 */
proto.insurance_service.InsuranceSplitLimits.prototype.setLiabilityPerAccident = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 liability_property_damage = 3;
 * @return {number}
 */
proto.insurance_service.InsuranceSplitLimits.prototype.getLiabilityPropertyDamage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.InsuranceSplitLimits} returns this
 */
proto.insurance_service.InsuranceSplitLimits.prototype.setLiabilityPropertyDamage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 uninsured_per_person = 4;
 * @return {number}
 */
proto.insurance_service.InsuranceSplitLimits.prototype.getUninsuredPerPerson = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.InsuranceSplitLimits} returns this
 */
proto.insurance_service.InsuranceSplitLimits.prototype.setUninsuredPerPerson = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 uninsured_per_accident = 5;
 * @return {number}
 */
proto.insurance_service.InsuranceSplitLimits.prototype.getUninsuredPerAccident = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.InsuranceSplitLimits} returns this
 */
proto.insurance_service.InsuranceSplitLimits.prototype.setUninsuredPerAccident = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 medical_payments = 7;
 * @return {number}
 */
proto.insurance_service.InsuranceSplitLimits.prototype.getMedicalPayments = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.InsuranceSplitLimits} returns this
 */
proto.insurance_service.InsuranceSplitLimits.prototype.setMedicalPayments = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 comprehensive_deductible = 8;
 * @return {number}
 */
proto.insurance_service.InsuranceSplitLimits.prototype.getComprehensiveDeductible = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.InsuranceSplitLimits} returns this
 */
proto.insurance_service.InsuranceSplitLimits.prototype.setComprehensiveDeductible = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 collision_deductible = 9;
 * @return {number}
 */
proto.insurance_service.InsuranceSplitLimits.prototype.getCollisionDeductible = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.InsuranceSplitLimits} returns this
 */
proto.insurance_service.InsuranceSplitLimits.prototype.setCollisionDeductible = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 rental_reimbursement = 10;
 * @return {number}
 */
proto.insurance_service.InsuranceSplitLimits.prototype.getRentalReimbursement = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.InsuranceSplitLimits} returns this
 */
proto.insurance_service.InsuranceSplitLimits.prototype.setRentalReimbursement = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 rental_reimbursement_max_days = 11;
 * @return {number}
 */
proto.insurance_service.InsuranceSplitLimits.prototype.getRentalReimbursementMaxDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.InsuranceSplitLimits} returns this
 */
proto.insurance_service.InsuranceSplitLimits.prototype.setRentalReimbursementMaxDays = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 towing_and_labor = 12;
 * @return {number}
 */
proto.insurance_service.InsuranceSplitLimits.prototype.getTowingAndLabor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.InsuranceSplitLimits} returns this
 */
proto.insurance_service.InsuranceSplitLimits.prototype.setTowingAndLabor = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.insurance_service.CreatePolicyChangeQuoteRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.CreatePolicyChangeQuoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.CreatePolicyChangeQuoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.CreatePolicyChangeQuoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.CreatePolicyChangeQuoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    additionalDriversList: jspb.Message.toObjectList(msg.getAdditionalDriversList(),
    insurance_pb.AdditionalDriver.toObject, includeInstance),
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.CreatePolicyChangeQuoteRequest}
 */
proto.insurance_service.CreatePolicyChangeQuoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.CreatePolicyChangeQuoteRequest;
  return proto.insurance_service.CreatePolicyChangeQuoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.CreatePolicyChangeQuoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.CreatePolicyChangeQuoteRequest}
 */
proto.insurance_service.CreatePolicyChangeQuoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = new insurance_pb.AdditionalDriver;
      reader.readMessage(value,insurance_pb.AdditionalDriver.deserializeBinaryFromReader);
      msg.addAdditionalDrivers(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.CreatePolicyChangeQuoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.CreatePolicyChangeQuoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.CreatePolicyChangeQuoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.CreatePolicyChangeQuoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAdditionalDriversList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      insurance_pb.AdditionalDriver.serializeBinaryToWriter
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string subscription_id = 4;
 * @return {string}
 */
proto.insurance_service.CreatePolicyChangeQuoteRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.CreatePolicyChangeQuoteRequest} returns this
 */
proto.insurance_service.CreatePolicyChangeQuoteRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated insurance.AdditionalDriver additional_drivers = 3;
 * @return {!Array<!proto.insurance.AdditionalDriver>}
 */
proto.insurance_service.CreatePolicyChangeQuoteRequest.prototype.getAdditionalDriversList = function() {
  return /** @type{!Array<!proto.insurance.AdditionalDriver>} */ (
    jspb.Message.getRepeatedWrapperField(this, insurance_pb.AdditionalDriver, 3));
};


/**
 * @param {!Array<!proto.insurance.AdditionalDriver>} value
 * @return {!proto.insurance_service.CreatePolicyChangeQuoteRequest} returns this
*/
proto.insurance_service.CreatePolicyChangeQuoteRequest.prototype.setAdditionalDriversList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.insurance.AdditionalDriver=} opt_value
 * @param {number=} opt_index
 * @return {!proto.insurance.AdditionalDriver}
 */
proto.insurance_service.CreatePolicyChangeQuoteRequest.prototype.addAdditionalDrivers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.insurance.AdditionalDriver, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.insurance_service.CreatePolicyChangeQuoteRequest} returns this
 */
proto.insurance_service.CreatePolicyChangeQuoteRequest.prototype.clearAdditionalDriversList = function() {
  return this.setAdditionalDriversList([]);
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.insurance_service.CreatePolicyChangeQuoteRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.CreatePolicyChangeQuoteRequest} returns this
 */
proto.insurance_service.CreatePolicyChangeQuoteRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.CreatePolicyChangeQuoteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.CreatePolicyChangeQuoteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    monthlyRate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    mileageRate: jspb.Message.getFieldWithDefault(msg, 4, 0),
    additionalDriversList: jspb.Message.toObjectList(msg.getAdditionalDriversList(),
    insurance_pb.AdditionalDriver.toObject, includeInstance),
    insuranceSplitLimits: (f = msg.getInsuranceSplitLimits()) && proto.insurance_service.InsuranceSplitLimits.toObject(includeInstance, f),
    deductible: jspb.Message.getFieldWithDefault(msg, 7, 0),
    limits: jspb.Message.getFieldWithDefault(msg, 8, ""),
    quoteId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    deprecatedQuoteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dailyRate: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.CreatePolicyChangeQuoteResponse}
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.CreatePolicyChangeQuoteResponse;
  return proto.insurance_service.CreatePolicyChangeQuoteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.CreatePolicyChangeQuoteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.CreatePolicyChangeQuoteResponse}
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthlyRate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMileageRate(value);
      break;
    case 5:
      var value = new insurance_pb.AdditionalDriver;
      reader.readMessage(value,insurance_pb.AdditionalDriver.deserializeBinaryFromReader);
      msg.addAdditionalDrivers(value);
      break;
    case 6:
      var value = new proto.insurance_service.InsuranceSplitLimits;
      reader.readMessage(value,proto.insurance_service.InsuranceSplitLimits.deserializeBinaryFromReader);
      msg.setInsuranceSplitLimits(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeductible(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLimits(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuoteId(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeprecatedQuoteId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDailyRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.CreatePolicyChangeQuoteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.CreatePolicyChangeQuoteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMonthlyRate();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMileageRate();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAdditionalDriversList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      insurance_pb.AdditionalDriver.serializeBinaryToWriter
    );
  }
  f = message.getInsuranceSplitLimits();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.insurance_service.InsuranceSplitLimits.serializeBinaryToWriter
    );
  }
  f = message.getDeductible();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getLimits();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getQuoteId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDeprecatedQuoteId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDailyRate();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 monthly_rate = 3;
 * @return {number}
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.prototype.getMonthlyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.CreatePolicyChangeQuoteResponse} returns this
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.prototype.setMonthlyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 mileage_rate = 4;
 * @return {number}
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.prototype.getMileageRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.CreatePolicyChangeQuoteResponse} returns this
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.prototype.setMileageRate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated insurance.AdditionalDriver additional_drivers = 5;
 * @return {!Array<!proto.insurance.AdditionalDriver>}
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.prototype.getAdditionalDriversList = function() {
  return /** @type{!Array<!proto.insurance.AdditionalDriver>} */ (
    jspb.Message.getRepeatedWrapperField(this, insurance_pb.AdditionalDriver, 5));
};


/**
 * @param {!Array<!proto.insurance.AdditionalDriver>} value
 * @return {!proto.insurance_service.CreatePolicyChangeQuoteResponse} returns this
*/
proto.insurance_service.CreatePolicyChangeQuoteResponse.prototype.setAdditionalDriversList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.insurance.AdditionalDriver=} opt_value
 * @param {number=} opt_index
 * @return {!proto.insurance.AdditionalDriver}
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.prototype.addAdditionalDrivers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.insurance.AdditionalDriver, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.insurance_service.CreatePolicyChangeQuoteResponse} returns this
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.prototype.clearAdditionalDriversList = function() {
  return this.setAdditionalDriversList([]);
};


/**
 * optional InsuranceSplitLimits insurance_split_limits = 6;
 * @return {?proto.insurance_service.InsuranceSplitLimits}
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.prototype.getInsuranceSplitLimits = function() {
  return /** @type{?proto.insurance_service.InsuranceSplitLimits} */ (
    jspb.Message.getWrapperField(this, proto.insurance_service.InsuranceSplitLimits, 6));
};


/**
 * @param {?proto.insurance_service.InsuranceSplitLimits|undefined} value
 * @return {!proto.insurance_service.CreatePolicyChangeQuoteResponse} returns this
*/
proto.insurance_service.CreatePolicyChangeQuoteResponse.prototype.setInsuranceSplitLimits = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.insurance_service.CreatePolicyChangeQuoteResponse} returns this
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.prototype.clearInsuranceSplitLimits = function() {
  return this.setInsuranceSplitLimits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.prototype.hasInsuranceSplitLimits = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 deductible = 7;
 * @return {number}
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.prototype.getDeductible = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.CreatePolicyChangeQuoteResponse} returns this
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.prototype.setDeductible = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string limits = 8;
 * @return {string}
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.prototype.getLimits = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.CreatePolicyChangeQuoteResponse} returns this
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.prototype.setLimits = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string quote_id = 9;
 * @return {string}
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.prototype.getQuoteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.CreatePolicyChangeQuoteResponse} returns this
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.prototype.setQuoteId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 deprecated_quote_id = 1;
 * @return {number}
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.prototype.getDeprecatedQuoteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.CreatePolicyChangeQuoteResponse} returns this
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.prototype.setDeprecatedQuoteId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 daily_rate = 2;
 * @return {number}
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.prototype.getDailyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.CreatePolicyChangeQuoteResponse} returns this
 */
proto.insurance_service.CreatePolicyChangeQuoteResponse.prototype.setDailyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.CreatePolicyChangeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.CreatePolicyChangeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.CreatePolicyChangeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.CreatePolicyChangeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    quoteId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    deprecatedQuoteId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.CreatePolicyChangeRequest}
 */
proto.insurance_service.CreatePolicyChangeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.CreatePolicyChangeRequest;
  return proto.insurance_service.CreatePolicyChangeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.CreatePolicyChangeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.CreatePolicyChangeRequest}
 */
proto.insurance_service.CreatePolicyChangeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuoteId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeprecatedQuoteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.CreatePolicyChangeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.CreatePolicyChangeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.CreatePolicyChangeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.CreatePolicyChangeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuoteId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDeprecatedQuoteId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.insurance_service.CreatePolicyChangeRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.CreatePolicyChangeRequest} returns this
 */
proto.insurance_service.CreatePolicyChangeRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 3;
 * @return {string}
 */
proto.insurance_service.CreatePolicyChangeRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.CreatePolicyChangeRequest} returns this
 */
proto.insurance_service.CreatePolicyChangeRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string quote_id = 4;
 * @return {string}
 */
proto.insurance_service.CreatePolicyChangeRequest.prototype.getQuoteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.CreatePolicyChangeRequest} returns this
 */
proto.insurance_service.CreatePolicyChangeRequest.prototype.setQuoteId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 deprecated_quote_id = 2;
 * @return {number}
 */
proto.insurance_service.CreatePolicyChangeRequest.prototype.getDeprecatedQuoteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.CreatePolicyChangeRequest} returns this
 */
proto.insurance_service.CreatePolicyChangeRequest.prototype.setDeprecatedQuoteId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.insurance_service.CreatePolicyChangeResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.CreatePolicyChangeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.CreatePolicyChangeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.CreatePolicyChangeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.CreatePolicyChangeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    monthlyRate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    mileageRate: jspb.Message.getFieldWithDefault(msg, 4, 0),
    additionalDriversList: jspb.Message.toObjectList(msg.getAdditionalDriversList(),
    insurance_pb.AdditionalDriver.toObject, includeInstance),
    insuranceSplitLimits: (f = msg.getInsuranceSplitLimits()) && proto.insurance_service.InsuranceSplitLimits.toObject(includeInstance, f),
    deductible: jspb.Message.getFieldWithDefault(msg, 7, 0),
    limits: jspb.Message.getFieldWithDefault(msg, 8, ""),
    quoteId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    deprecatedQuoteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dailyRate: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.CreatePolicyChangeResponse}
 */
proto.insurance_service.CreatePolicyChangeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.CreatePolicyChangeResponse;
  return proto.insurance_service.CreatePolicyChangeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.CreatePolicyChangeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.CreatePolicyChangeResponse}
 */
proto.insurance_service.CreatePolicyChangeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthlyRate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMileageRate(value);
      break;
    case 5:
      var value = new insurance_pb.AdditionalDriver;
      reader.readMessage(value,insurance_pb.AdditionalDriver.deserializeBinaryFromReader);
      msg.addAdditionalDrivers(value);
      break;
    case 6:
      var value = new proto.insurance_service.InsuranceSplitLimits;
      reader.readMessage(value,proto.insurance_service.InsuranceSplitLimits.deserializeBinaryFromReader);
      msg.setInsuranceSplitLimits(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeductible(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLimits(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuoteId(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeprecatedQuoteId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDailyRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.CreatePolicyChangeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.CreatePolicyChangeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.CreatePolicyChangeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.CreatePolicyChangeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMonthlyRate();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMileageRate();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAdditionalDriversList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      insurance_pb.AdditionalDriver.serializeBinaryToWriter
    );
  }
  f = message.getInsuranceSplitLimits();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.insurance_service.InsuranceSplitLimits.serializeBinaryToWriter
    );
  }
  f = message.getDeductible();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getLimits();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getQuoteId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDeprecatedQuoteId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDailyRate();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 monthly_rate = 3;
 * @return {number}
 */
proto.insurance_service.CreatePolicyChangeResponse.prototype.getMonthlyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.CreatePolicyChangeResponse} returns this
 */
proto.insurance_service.CreatePolicyChangeResponse.prototype.setMonthlyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 mileage_rate = 4;
 * @return {number}
 */
proto.insurance_service.CreatePolicyChangeResponse.prototype.getMileageRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.CreatePolicyChangeResponse} returns this
 */
proto.insurance_service.CreatePolicyChangeResponse.prototype.setMileageRate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated insurance.AdditionalDriver additional_drivers = 5;
 * @return {!Array<!proto.insurance.AdditionalDriver>}
 */
proto.insurance_service.CreatePolicyChangeResponse.prototype.getAdditionalDriversList = function() {
  return /** @type{!Array<!proto.insurance.AdditionalDriver>} */ (
    jspb.Message.getRepeatedWrapperField(this, insurance_pb.AdditionalDriver, 5));
};


/**
 * @param {!Array<!proto.insurance.AdditionalDriver>} value
 * @return {!proto.insurance_service.CreatePolicyChangeResponse} returns this
*/
proto.insurance_service.CreatePolicyChangeResponse.prototype.setAdditionalDriversList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.insurance.AdditionalDriver=} opt_value
 * @param {number=} opt_index
 * @return {!proto.insurance.AdditionalDriver}
 */
proto.insurance_service.CreatePolicyChangeResponse.prototype.addAdditionalDrivers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.insurance.AdditionalDriver, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.insurance_service.CreatePolicyChangeResponse} returns this
 */
proto.insurance_service.CreatePolicyChangeResponse.prototype.clearAdditionalDriversList = function() {
  return this.setAdditionalDriversList([]);
};


/**
 * optional InsuranceSplitLimits insurance_split_limits = 6;
 * @return {?proto.insurance_service.InsuranceSplitLimits}
 */
proto.insurance_service.CreatePolicyChangeResponse.prototype.getInsuranceSplitLimits = function() {
  return /** @type{?proto.insurance_service.InsuranceSplitLimits} */ (
    jspb.Message.getWrapperField(this, proto.insurance_service.InsuranceSplitLimits, 6));
};


/**
 * @param {?proto.insurance_service.InsuranceSplitLimits|undefined} value
 * @return {!proto.insurance_service.CreatePolicyChangeResponse} returns this
*/
proto.insurance_service.CreatePolicyChangeResponse.prototype.setInsuranceSplitLimits = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.insurance_service.CreatePolicyChangeResponse} returns this
 */
proto.insurance_service.CreatePolicyChangeResponse.prototype.clearInsuranceSplitLimits = function() {
  return this.setInsuranceSplitLimits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.insurance_service.CreatePolicyChangeResponse.prototype.hasInsuranceSplitLimits = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 deductible = 7;
 * @return {number}
 */
proto.insurance_service.CreatePolicyChangeResponse.prototype.getDeductible = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.CreatePolicyChangeResponse} returns this
 */
proto.insurance_service.CreatePolicyChangeResponse.prototype.setDeductible = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string limits = 8;
 * @return {string}
 */
proto.insurance_service.CreatePolicyChangeResponse.prototype.getLimits = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.CreatePolicyChangeResponse} returns this
 */
proto.insurance_service.CreatePolicyChangeResponse.prototype.setLimits = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string quote_id = 9;
 * @return {string}
 */
proto.insurance_service.CreatePolicyChangeResponse.prototype.getQuoteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.CreatePolicyChangeResponse} returns this
 */
proto.insurance_service.CreatePolicyChangeResponse.prototype.setQuoteId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 deprecated_quote_id = 1;
 * @return {number}
 */
proto.insurance_service.CreatePolicyChangeResponse.prototype.getDeprecatedQuoteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.CreatePolicyChangeResponse} returns this
 */
proto.insurance_service.CreatePolicyChangeResponse.prototype.setDeprecatedQuoteId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 daily_rate = 2;
 * @return {number}
 */
proto.insurance_service.CreatePolicyChangeResponse.prototype.getDailyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.CreatePolicyChangeResponse} returns this
 */
proto.insurance_service.CreatePolicyChangeResponse.prototype.setDailyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.QualifyForLibertyInsuranceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.QualifyForLibertyInsuranceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.QualifyForLibertyInsuranceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.QualifyForLibertyInsuranceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.QualifyForLibertyInsuranceRequest}
 */
proto.insurance_service.QualifyForLibertyInsuranceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.QualifyForLibertyInsuranceRequest;
  return proto.insurance_service.QualifyForLibertyInsuranceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.QualifyForLibertyInsuranceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.QualifyForLibertyInsuranceRequest}
 */
proto.insurance_service.QualifyForLibertyInsuranceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.QualifyForLibertyInsuranceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.QualifyForLibertyInsuranceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.QualifyForLibertyInsuranceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.QualifyForLibertyInsuranceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.insurance_service.QualifyForLibertyInsuranceRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.QualifyForLibertyInsuranceRequest} returns this
 */
proto.insurance_service.QualifyForLibertyInsuranceRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.QualifyForLibertyInsuranceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.QualifyForLibertyInsuranceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.QualifyForLibertyInsuranceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.QualifyForLibertyInsuranceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isQualified: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.QualifyForLibertyInsuranceResponse}
 */
proto.insurance_service.QualifyForLibertyInsuranceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.QualifyForLibertyInsuranceResponse;
  return proto.insurance_service.QualifyForLibertyInsuranceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.QualifyForLibertyInsuranceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.QualifyForLibertyInsuranceResponse}
 */
proto.insurance_service.QualifyForLibertyInsuranceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsQualified(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.QualifyForLibertyInsuranceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.QualifyForLibertyInsuranceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.QualifyForLibertyInsuranceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.QualifyForLibertyInsuranceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsQualified();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_qualified = 1;
 * @return {boolean}
 */
proto.insurance_service.QualifyForLibertyInsuranceResponse.prototype.getIsQualified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.insurance_service.QualifyForLibertyInsuranceResponse} returns this
 */
proto.insurance_service.QualifyForLibertyInsuranceResponse.prototype.setIsQualified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.GetInsuredDriversRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.GetInsuredDriversRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.GetInsuredDriversRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetInsuredDriversRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.GetInsuredDriversRequest}
 */
proto.insurance_service.GetInsuredDriversRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.GetInsuredDriversRequest;
  return proto.insurance_service.GetInsuredDriversRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.GetInsuredDriversRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.GetInsuredDriversRequest}
 */
proto.insurance_service.GetInsuredDriversRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.GetInsuredDriversRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.GetInsuredDriversRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.GetInsuredDriversRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetInsuredDriversRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.insurance_service.GetInsuredDriversRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.GetInsuredDriversRequest} returns this
 */
proto.insurance_service.GetInsuredDriversRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.insurance_service.GetInsuredDriversRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.GetInsuredDriversRequest} returns this
 */
proto.insurance_service.GetInsuredDriversRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.insurance_service.GetInsuredDriversResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.GetInsuredDriversResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.GetInsuredDriversResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.GetInsuredDriversResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetInsuredDriversResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    driversList: jspb.Message.toObjectList(msg.getDriversList(),
    insurance_pb.Driver.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.GetInsuredDriversResponse}
 */
proto.insurance_service.GetInsuredDriversResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.GetInsuredDriversResponse;
  return proto.insurance_service.GetInsuredDriversResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.GetInsuredDriversResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.GetInsuredDriversResponse}
 */
proto.insurance_service.GetInsuredDriversResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new insurance_pb.Driver;
      reader.readMessage(value,insurance_pb.Driver.deserializeBinaryFromReader);
      msg.addDrivers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.GetInsuredDriversResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.GetInsuredDriversResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.GetInsuredDriversResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetInsuredDriversResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDriversList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      insurance_pb.Driver.serializeBinaryToWriter
    );
  }
};


/**
 * repeated insurance.Driver drivers = 1;
 * @return {!Array<!proto.insurance.Driver>}
 */
proto.insurance_service.GetInsuredDriversResponse.prototype.getDriversList = function() {
  return /** @type{!Array<!proto.insurance.Driver>} */ (
    jspb.Message.getRepeatedWrapperField(this, insurance_pb.Driver, 1));
};


/**
 * @param {!Array<!proto.insurance.Driver>} value
 * @return {!proto.insurance_service.GetInsuredDriversResponse} returns this
*/
proto.insurance_service.GetInsuredDriversResponse.prototype.setDriversList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.insurance.Driver=} opt_value
 * @param {number=} opt_index
 * @return {!proto.insurance.Driver}
 */
proto.insurance_service.GetInsuredDriversResponse.prototype.addDrivers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.insurance.Driver, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.insurance_service.GetInsuredDriversResponse} returns this
 */
proto.insurance_service.GetInsuredDriversResponse.prototype.clearDriversList = function() {
  return this.setDriversList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.CancelInsurancePolicyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.CancelInsurancePolicyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.CancelInsurancePolicyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.CancelInsurancePolicyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    endDateMilli: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.CancelInsurancePolicyRequest}
 */
proto.insurance_service.CancelInsurancePolicyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.CancelInsurancePolicyRequest;
  return proto.insurance_service.CancelInsurancePolicyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.CancelInsurancePolicyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.CancelInsurancePolicyRequest}
 */
proto.insurance_service.CancelInsurancePolicyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndDateMilli(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.CancelInsurancePolicyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.CancelInsurancePolicyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.CancelInsurancePolicyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.CancelInsurancePolicyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEndDateMilli();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string subscription_id = 3;
 * @return {string}
 */
proto.insurance_service.CancelInsurancePolicyRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.CancelInsurancePolicyRequest} returns this
 */
proto.insurance_service.CancelInsurancePolicyRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.insurance_service.CancelInsurancePolicyRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.CancelInsurancePolicyRequest} returns this
 */
proto.insurance_service.CancelInsurancePolicyRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 end_date_milli = 2;
 * @return {number}
 */
proto.insurance_service.CancelInsurancePolicyRequest.prototype.getEndDateMilli = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.CancelInsurancePolicyRequest} returns this
 */
proto.insurance_service.CancelInsurancePolicyRequest.prototype.setEndDateMilli = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.CancelInsurancePolicyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.CancelInsurancePolicyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.CancelInsurancePolicyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.CancelInsurancePolicyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.CancelInsurancePolicyResponse}
 */
proto.insurance_service.CancelInsurancePolicyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.CancelInsurancePolicyResponse;
  return proto.insurance_service.CancelInsurancePolicyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.CancelInsurancePolicyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.CancelInsurancePolicyResponse}
 */
proto.insurance_service.CancelInsurancePolicyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.CancelInsurancePolicyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.CancelInsurancePolicyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.CancelInsurancePolicyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.CancelInsurancePolicyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.insurance_service.CancelInsurancePolicyResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.insurance_service.CancelInsurancePolicyResponse} returns this
 */
proto.insurance_service.CancelInsurancePolicyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.ExtendInsurancePolicyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.ExtendInsurancePolicyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.ExtendInsurancePolicyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.ExtendInsurancePolicyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.ExtendInsurancePolicyRequest}
 */
proto.insurance_service.ExtendInsurancePolicyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.ExtendInsurancePolicyRequest;
  return proto.insurance_service.ExtendInsurancePolicyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.ExtendInsurancePolicyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.ExtendInsurancePolicyRequest}
 */
proto.insurance_service.ExtendInsurancePolicyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.ExtendInsurancePolicyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.ExtendInsurancePolicyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.ExtendInsurancePolicyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.ExtendInsurancePolicyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.insurance_service.ExtendInsurancePolicyRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.ExtendInsurancePolicyRequest} returns this
 */
proto.insurance_service.ExtendInsurancePolicyRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.ExtendInsurancePolicyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.ExtendInsurancePolicyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.ExtendInsurancePolicyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.ExtendInsurancePolicyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    policyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    policyNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    effectiveDate: jspb.Message.getFieldWithDefault(msg, 4, 0),
    expirationDate: jspb.Message.getFieldWithDefault(msg, 5, 0),
    dailyRate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    monthlyRate: jspb.Message.getFieldWithDefault(msg, 7, 0),
    excessMileageRate: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.ExtendInsurancePolicyResponse}
 */
proto.insurance_service.ExtendInsurancePolicyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.ExtendInsurancePolicyResponse;
  return proto.insurance_service.ExtendInsurancePolicyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.ExtendInsurancePolicyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.ExtendInsurancePolicyResponse}
 */
proto.insurance_service.ExtendInsurancePolicyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPolicyId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPolicyNumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEffectiveDate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpirationDate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDailyRate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthlyRate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExcessMileageRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.ExtendInsurancePolicyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.ExtendInsurancePolicyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.ExtendInsurancePolicyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.ExtendInsurancePolicyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPolicyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPolicyNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEffectiveDate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getExpirationDate();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getDailyRate();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMonthlyRate();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getExcessMileageRate();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.insurance_service.ExtendInsurancePolicyResponse.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.ExtendInsurancePolicyResponse} returns this
 */
proto.insurance_service.ExtendInsurancePolicyResponse.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 policy_id = 2;
 * @return {number}
 */
proto.insurance_service.ExtendInsurancePolicyResponse.prototype.getPolicyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.ExtendInsurancePolicyResponse} returns this
 */
proto.insurance_service.ExtendInsurancePolicyResponse.prototype.setPolicyId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string policy_number = 3;
 * @return {string}
 */
proto.insurance_service.ExtendInsurancePolicyResponse.prototype.getPolicyNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.ExtendInsurancePolicyResponse} returns this
 */
proto.insurance_service.ExtendInsurancePolicyResponse.prototype.setPolicyNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 effective_date = 4;
 * @return {number}
 */
proto.insurance_service.ExtendInsurancePolicyResponse.prototype.getEffectiveDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.ExtendInsurancePolicyResponse} returns this
 */
proto.insurance_service.ExtendInsurancePolicyResponse.prototype.setEffectiveDate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 expiration_date = 5;
 * @return {number}
 */
proto.insurance_service.ExtendInsurancePolicyResponse.prototype.getExpirationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.ExtendInsurancePolicyResponse} returns this
 */
proto.insurance_service.ExtendInsurancePolicyResponse.prototype.setExpirationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 daily_rate = 6;
 * @return {number}
 */
proto.insurance_service.ExtendInsurancePolicyResponse.prototype.getDailyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.ExtendInsurancePolicyResponse} returns this
 */
proto.insurance_service.ExtendInsurancePolicyResponse.prototype.setDailyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 monthly_rate = 7;
 * @return {number}
 */
proto.insurance_service.ExtendInsurancePolicyResponse.prototype.getMonthlyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.ExtendInsurancePolicyResponse} returns this
 */
proto.insurance_service.ExtendInsurancePolicyResponse.prototype.setMonthlyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 excess_mileage_rate = 8;
 * @return {number}
 */
proto.insurance_service.ExtendInsurancePolicyResponse.prototype.getExcessMileageRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.ExtendInsurancePolicyResponse} returns this
 */
proto.insurance_service.ExtendInsurancePolicyResponse.prototype.setExcessMileageRate = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.GetUpdatedInsurancePolicyCardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.GetUpdatedInsurancePolicyCardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.GetUpdatedInsurancePolicyCardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetUpdatedInsurancePolicyCardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.GetUpdatedInsurancePolicyCardRequest}
 */
proto.insurance_service.GetUpdatedInsurancePolicyCardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.GetUpdatedInsurancePolicyCardRequest;
  return proto.insurance_service.GetUpdatedInsurancePolicyCardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.GetUpdatedInsurancePolicyCardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.GetUpdatedInsurancePolicyCardRequest}
 */
proto.insurance_service.GetUpdatedInsurancePolicyCardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.GetUpdatedInsurancePolicyCardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.GetUpdatedInsurancePolicyCardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.GetUpdatedInsurancePolicyCardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetUpdatedInsurancePolicyCardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.insurance_service.GetUpdatedInsurancePolicyCardRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.GetUpdatedInsurancePolicyCardRequest} returns this
 */
proto.insurance_service.GetUpdatedInsurancePolicyCardRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.GetUpdatedInsurancePolicyCardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.GetUpdatedInsurancePolicyCardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.GetUpdatedInsurancePolicyCardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetUpdatedInsurancePolicyCardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.GetUpdatedInsurancePolicyCardResponse}
 */
proto.insurance_service.GetUpdatedInsurancePolicyCardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.GetUpdatedInsurancePolicyCardResponse;
  return proto.insurance_service.GetUpdatedInsurancePolicyCardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.GetUpdatedInsurancePolicyCardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.GetUpdatedInsurancePolicyCardResponse}
 */
proto.insurance_service.GetUpdatedInsurancePolicyCardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.GetUpdatedInsurancePolicyCardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.GetUpdatedInsurancePolicyCardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.GetUpdatedInsurancePolicyCardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetUpdatedInsurancePolicyCardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string document_id = 1;
 * @return {string}
 */
proto.insurance_service.GetUpdatedInsurancePolicyCardResponse.prototype.getDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.GetUpdatedInsurancePolicyCardResponse} returns this
 */
proto.insurance_service.GetUpdatedInsurancePolicyCardResponse.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.GetInsuranceStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.GetInsuranceStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.GetInsuranceStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetInsuranceStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.GetInsuranceStatusRequest}
 */
proto.insurance_service.GetInsuranceStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.GetInsuranceStatusRequest;
  return proto.insurance_service.GetInsuranceStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.GetInsuranceStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.GetInsuranceStatusRequest}
 */
proto.insurance_service.GetInsuranceStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.GetInsuranceStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.GetInsuranceStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.GetInsuranceStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetInsuranceStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.insurance_service.GetInsuranceStatusRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.GetInsuranceStatusRequest} returns this
 */
proto.insurance_service.GetInsuranceStatusRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.insurance_service.GetInsuranceStatusRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.GetInsuranceStatusRequest} returns this
 */
proto.insurance_service.GetInsuranceStatusRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.GetInsuranceStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.GetInsuranceStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.GetInsuranceStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetInsuranceStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    policyId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    effectiveDate: jspb.Message.getFieldWithDefault(msg, 4, 0),
    monthlyRate: jspb.Message.getFieldWithDefault(msg, 7, 0),
    excessMileageRate: jspb.Message.getFieldWithDefault(msg, 8, 0),
    isQualified: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    status: jspb.Message.getFieldWithDefault(msg, 10, 0),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    deprecatedPolicyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    expirationDate: jspb.Message.getFieldWithDefault(msg, 5, 0),
    dailyRate: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.GetInsuranceStatusResponse}
 */
proto.insurance_service.GetInsuranceStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.GetInsuranceStatusResponse;
  return proto.insurance_service.GetInsuranceStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.GetInsuranceStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.GetInsuranceStatusResponse}
 */
proto.insurance_service.GetInsuranceStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPolicyId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEffectiveDate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthlyRate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExcessMileageRate(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsQualified(value);
      break;
    case 10:
      var value = /** @type {!proto.insurance.PolicyStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeprecatedPolicyId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpirationDate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDailyRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.GetInsuranceStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.GetInsuranceStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.GetInsuranceStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetInsuranceStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPolicyId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEffectiveDate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getMonthlyRate();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getExcessMileageRate();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getIsQualified();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDeprecatedPolicyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getExpirationDate();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getDailyRate();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.insurance_service.GetInsuranceStatusResponse.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.GetInsuranceStatusResponse} returns this
 */
proto.insurance_service.GetInsuranceStatusResponse.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string policy_id = 3;
 * @return {string}
 */
proto.insurance_service.GetInsuranceStatusResponse.prototype.getPolicyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.GetInsuranceStatusResponse} returns this
 */
proto.insurance_service.GetInsuranceStatusResponse.prototype.setPolicyId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 effective_date = 4;
 * @return {number}
 */
proto.insurance_service.GetInsuranceStatusResponse.prototype.getEffectiveDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.GetInsuranceStatusResponse} returns this
 */
proto.insurance_service.GetInsuranceStatusResponse.prototype.setEffectiveDate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 monthly_rate = 7;
 * @return {number}
 */
proto.insurance_service.GetInsuranceStatusResponse.prototype.getMonthlyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.GetInsuranceStatusResponse} returns this
 */
proto.insurance_service.GetInsuranceStatusResponse.prototype.setMonthlyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 excess_mileage_rate = 8;
 * @return {number}
 */
proto.insurance_service.GetInsuranceStatusResponse.prototype.getExcessMileageRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.GetInsuranceStatusResponse} returns this
 */
proto.insurance_service.GetInsuranceStatusResponse.prototype.setExcessMileageRate = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool is_qualified = 9;
 * @return {boolean}
 */
proto.insurance_service.GetInsuranceStatusResponse.prototype.getIsQualified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.insurance_service.GetInsuranceStatusResponse} returns this
 */
proto.insurance_service.GetInsuranceStatusResponse.prototype.setIsQualified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional insurance.PolicyStatus status = 10;
 * @return {!proto.insurance.PolicyStatus}
 */
proto.insurance_service.GetInsuranceStatusResponse.prototype.getStatus = function() {
  return /** @type {!proto.insurance.PolicyStatus} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.insurance.PolicyStatus} value
 * @return {!proto.insurance_service.GetInsuranceStatusResponse} returns this
 */
proto.insurance_service.GetInsuranceStatusResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string subscription_id = 11;
 * @return {string}
 */
proto.insurance_service.GetInsuranceStatusResponse.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.GetInsuranceStatusResponse} returns this
 */
proto.insurance_service.GetInsuranceStatusResponse.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int32 deprecated_policy_id = 2;
 * @return {number}
 */
proto.insurance_service.GetInsuranceStatusResponse.prototype.getDeprecatedPolicyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.GetInsuranceStatusResponse} returns this
 */
proto.insurance_service.GetInsuranceStatusResponse.prototype.setDeprecatedPolicyId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 expiration_date = 5;
 * @return {number}
 */
proto.insurance_service.GetInsuranceStatusResponse.prototype.getExpirationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.GetInsuranceStatusResponse} returns this
 */
proto.insurance_service.GetInsuranceStatusResponse.prototype.setExpirationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 daily_rate = 6;
 * @return {number}
 */
proto.insurance_service.GetInsuranceStatusResponse.prototype.getDailyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.GetInsuranceStatusResponse} returns this
 */
proto.insurance_service.GetInsuranceStatusResponse.prototype.setDailyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.BulkUpdatePoliciesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.BulkUpdatePoliciesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.BulkUpdatePoliciesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.BulkUpdatePoliciesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cutoffDateUnixMilli: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.BulkUpdatePoliciesRequest}
 */
proto.insurance_service.BulkUpdatePoliciesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.BulkUpdatePoliciesRequest;
  return proto.insurance_service.BulkUpdatePoliciesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.BulkUpdatePoliciesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.BulkUpdatePoliciesRequest}
 */
proto.insurance_service.BulkUpdatePoliciesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCutoffDateUnixMilli(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.BulkUpdatePoliciesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.BulkUpdatePoliciesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.BulkUpdatePoliciesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.BulkUpdatePoliciesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCutoffDateUnixMilli();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 cutoff_date_unix_milli = 1;
 * @return {number}
 */
proto.insurance_service.BulkUpdatePoliciesRequest.prototype.getCutoffDateUnixMilli = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.BulkUpdatePoliciesRequest} returns this
 */
proto.insurance_service.BulkUpdatePoliciesRequest.prototype.setCutoffDateUnixMilli = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.BulkUpdatePoliciesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.BulkUpdatePoliciesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.BulkUpdatePoliciesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.BulkUpdatePoliciesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.BulkUpdatePoliciesResponse}
 */
proto.insurance_service.BulkUpdatePoliciesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.BulkUpdatePoliciesResponse;
  return proto.insurance_service.BulkUpdatePoliciesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.BulkUpdatePoliciesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.BulkUpdatePoliciesResponse}
 */
proto.insurance_service.BulkUpdatePoliciesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.BulkUpdatePoliciesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.BulkUpdatePoliciesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.BulkUpdatePoliciesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.BulkUpdatePoliciesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.GetExternalPoliciesFromDsotRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.GetExternalPoliciesFromDsotRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.GetExternalPoliciesFromDsotRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetExternalPoliciesFromDsotRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    batchSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    wait: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.GetExternalPoliciesFromDsotRequest}
 */
proto.insurance_service.GetExternalPoliciesFromDsotRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.GetExternalPoliciesFromDsotRequest;
  return proto.insurance_service.GetExternalPoliciesFromDsotRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.GetExternalPoliciesFromDsotRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.GetExternalPoliciesFromDsotRequest}
 */
proto.insurance_service.GetExternalPoliciesFromDsotRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBatchSize(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWait(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.GetExternalPoliciesFromDsotRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.GetExternalPoliciesFromDsotRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.GetExternalPoliciesFromDsotRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetExternalPoliciesFromDsotRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatchSize();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getWait();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional uint32 batch_size = 1;
 * @return {number}
 */
proto.insurance_service.GetExternalPoliciesFromDsotRequest.prototype.getBatchSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.GetExternalPoliciesFromDsotRequest} returns this
 */
proto.insurance_service.GetExternalPoliciesFromDsotRequest.prototype.setBatchSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool wait = 2;
 * @return {boolean}
 */
proto.insurance_service.GetExternalPoliciesFromDsotRequest.prototype.getWait = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.insurance_service.GetExternalPoliciesFromDsotRequest} returns this
 */
proto.insurance_service.GetExternalPoliciesFromDsotRequest.prototype.setWait = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.GetExternalPoliciesFromDsotResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.GetExternalPoliciesFromDsotResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.GetExternalPoliciesFromDsotResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetExternalPoliciesFromDsotResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.GetExternalPoliciesFromDsotResponse}
 */
proto.insurance_service.GetExternalPoliciesFromDsotResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.GetExternalPoliciesFromDsotResponse;
  return proto.insurance_service.GetExternalPoliciesFromDsotResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.GetExternalPoliciesFromDsotResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.GetExternalPoliciesFromDsotResponse}
 */
proto.insurance_service.GetExternalPoliciesFromDsotResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.GetExternalPoliciesFromDsotResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.GetExternalPoliciesFromDsotResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.GetExternalPoliciesFromDsotResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.GetExternalPoliciesFromDsotResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.ImportInsuranceDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.ImportInsuranceDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.ImportInsuranceDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.ImportInsuranceDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.ImportInsuranceDataRequest}
 */
proto.insurance_service.ImportInsuranceDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.ImportInsuranceDataRequest;
  return proto.insurance_service.ImportInsuranceDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.ImportInsuranceDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.ImportInsuranceDataRequest}
 */
proto.insurance_service.ImportInsuranceDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.ImportInsuranceDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.ImportInsuranceDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.ImportInsuranceDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.ImportInsuranceDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.ImportInsuranceDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.ImportInsuranceDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.ImportInsuranceDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.ImportInsuranceDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.ImportInsuranceDataResponse}
 */
proto.insurance_service.ImportInsuranceDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.ImportInsuranceDataResponse;
  return proto.insurance_service.ImportInsuranceDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.ImportInsuranceDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.ImportInsuranceDataResponse}
 */
proto.insurance_service.ImportInsuranceDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.ImportInsuranceDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.ImportInsuranceDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.ImportInsuranceDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.ImportInsuranceDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.IsQualifiedForLiberty.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.IsQualifiedForLiberty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.IsQualifiedForLiberty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.IsQualifiedForLiberty.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isQualified: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.IsQualifiedForLiberty}
 */
proto.insurance_service.IsQualifiedForLiberty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.IsQualifiedForLiberty;
  return proto.insurance_service.IsQualifiedForLiberty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.IsQualifiedForLiberty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.IsQualifiedForLiberty}
 */
proto.insurance_service.IsQualifiedForLiberty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsQualified(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.IsQualifiedForLiberty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.IsQualifiedForLiberty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.IsQualifiedForLiberty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.IsQualifiedForLiberty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsQualified();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.insurance_service.IsQualifiedForLiberty.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.IsQualifiedForLiberty} returns this
 */
proto.insurance_service.IsQualifiedForLiberty.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_qualified = 2;
 * @return {boolean}
 */
proto.insurance_service.IsQualifiedForLiberty.prototype.getIsQualified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.insurance_service.IsQualifiedForLiberty} returns this
 */
proto.insurance_service.IsQualifiedForLiberty.prototype.setIsQualified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.insurance_service.InsurancePolicyCreated.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.InsurancePolicyCreated.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.InsurancePolicyCreated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.InsurancePolicyCreated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.InsurancePolicyCreated.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dailyRate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    monthlyRate: jspb.Message.getFieldWithDefault(msg, 7, 0),
    excessMileageRate: jspb.Message.getFieldWithDefault(msg, 8, 0),
    insuranceCardDocumentId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    insuranceSplitLimits: (f = msg.getInsuranceSplitLimits()) && proto.insurance_service.InsuranceSplitLimits.toObject(includeInstance, f),
    additionalDriversList: jspb.Message.toObjectList(msg.getAdditionalDriversList(),
    insurance_pb.AdditionalDriver.toObject, includeInstance),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    make: jspb.Message.getFieldWithDefault(msg, 13, ""),
    model: jspb.Message.getFieldWithDefault(msg, 14, ""),
    year: jspb.Message.getFieldWithDefault(msg, 15, 0),
    deductible: jspb.Message.getFieldWithDefault(msg, 16, 0),
    limits: jspb.Message.getFieldWithDefault(msg, 17, ""),
    policyId: jspb.Message.getFieldWithDefault(msg, 18, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 19, ""),
    deprecatedPolicyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    deprecatedPolicyNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    deprecatedEffectiveDate: jspb.Message.getFieldWithDefault(msg, 4, 0),
    deprecatedExpirationDate: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.InsurancePolicyCreated}
 */
proto.insurance_service.InsurancePolicyCreated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.InsurancePolicyCreated;
  return proto.insurance_service.InsurancePolicyCreated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.InsurancePolicyCreated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.InsurancePolicyCreated}
 */
proto.insurance_service.InsurancePolicyCreated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDailyRate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthlyRate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExcessMileageRate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setInsuranceCardDocumentId(value);
      break;
    case 10:
      var value = new proto.insurance_service.InsuranceSplitLimits;
      reader.readMessage(value,proto.insurance_service.InsuranceSplitLimits.deserializeBinaryFromReader);
      msg.setInsuranceSplitLimits(value);
      break;
    case 11:
      var value = new insurance_pb.AdditionalDriver;
      reader.readMessage(value,insurance_pb.AdditionalDriver.deserializeBinaryFromReader);
      msg.addAdditionalDrivers(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeductible(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setLimits(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setPolicyId(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeprecatedPolicyId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeprecatedPolicyNumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeprecatedEffectiveDate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeprecatedExpirationDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.InsurancePolicyCreated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.InsurancePolicyCreated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.InsurancePolicyCreated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.InsurancePolicyCreated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDailyRate();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMonthlyRate();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getExcessMileageRate();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getInsuranceCardDocumentId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getInsuranceSplitLimits();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.insurance_service.InsuranceSplitLimits.serializeBinaryToWriter
    );
  }
  f = message.getAdditionalDriversList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      insurance_pb.AdditionalDriver.serializeBinaryToWriter
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getDeductible();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getLimits();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPolicyId();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getDeprecatedPolicyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDeprecatedPolicyNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDeprecatedEffectiveDate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getDeprecatedExpirationDate();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.insurance_service.InsurancePolicyCreated.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.InsurancePolicyCreated} returns this
 */
proto.insurance_service.InsurancePolicyCreated.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 daily_rate = 6;
 * @return {number}
 */
proto.insurance_service.InsurancePolicyCreated.prototype.getDailyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.InsurancePolicyCreated} returns this
 */
proto.insurance_service.InsurancePolicyCreated.prototype.setDailyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 monthly_rate = 7;
 * @return {number}
 */
proto.insurance_service.InsurancePolicyCreated.prototype.getMonthlyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.InsurancePolicyCreated} returns this
 */
proto.insurance_service.InsurancePolicyCreated.prototype.setMonthlyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 excess_mileage_rate = 8;
 * @return {number}
 */
proto.insurance_service.InsurancePolicyCreated.prototype.getExcessMileageRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.InsurancePolicyCreated} returns this
 */
proto.insurance_service.InsurancePolicyCreated.prototype.setExcessMileageRate = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string insurance_card_document_id = 9;
 * @return {string}
 */
proto.insurance_service.InsurancePolicyCreated.prototype.getInsuranceCardDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.InsurancePolicyCreated} returns this
 */
proto.insurance_service.InsurancePolicyCreated.prototype.setInsuranceCardDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional InsuranceSplitLimits insurance_split_limits = 10;
 * @return {?proto.insurance_service.InsuranceSplitLimits}
 */
proto.insurance_service.InsurancePolicyCreated.prototype.getInsuranceSplitLimits = function() {
  return /** @type{?proto.insurance_service.InsuranceSplitLimits} */ (
    jspb.Message.getWrapperField(this, proto.insurance_service.InsuranceSplitLimits, 10));
};


/**
 * @param {?proto.insurance_service.InsuranceSplitLimits|undefined} value
 * @return {!proto.insurance_service.InsurancePolicyCreated} returns this
*/
proto.insurance_service.InsurancePolicyCreated.prototype.setInsuranceSplitLimits = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.insurance_service.InsurancePolicyCreated} returns this
 */
proto.insurance_service.InsurancePolicyCreated.prototype.clearInsuranceSplitLimits = function() {
  return this.setInsuranceSplitLimits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.insurance_service.InsurancePolicyCreated.prototype.hasInsuranceSplitLimits = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated insurance.AdditionalDriver additional_drivers = 11;
 * @return {!Array<!proto.insurance.AdditionalDriver>}
 */
proto.insurance_service.InsurancePolicyCreated.prototype.getAdditionalDriversList = function() {
  return /** @type{!Array<!proto.insurance.AdditionalDriver>} */ (
    jspb.Message.getRepeatedWrapperField(this, insurance_pb.AdditionalDriver, 11));
};


/**
 * @param {!Array<!proto.insurance.AdditionalDriver>} value
 * @return {!proto.insurance_service.InsurancePolicyCreated} returns this
*/
proto.insurance_service.InsurancePolicyCreated.prototype.setAdditionalDriversList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.insurance.AdditionalDriver=} opt_value
 * @param {number=} opt_index
 * @return {!proto.insurance.AdditionalDriver}
 */
proto.insurance_service.InsurancePolicyCreated.prototype.addAdditionalDrivers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.insurance.AdditionalDriver, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.insurance_service.InsurancePolicyCreated} returns this
 */
proto.insurance_service.InsurancePolicyCreated.prototype.clearAdditionalDriversList = function() {
  return this.setAdditionalDriversList([]);
};


/**
 * optional string vehicle_id = 12;
 * @return {string}
 */
proto.insurance_service.InsurancePolicyCreated.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.InsurancePolicyCreated} returns this
 */
proto.insurance_service.InsurancePolicyCreated.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string make = 13;
 * @return {string}
 */
proto.insurance_service.InsurancePolicyCreated.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.InsurancePolicyCreated} returns this
 */
proto.insurance_service.InsurancePolicyCreated.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string model = 14;
 * @return {string}
 */
proto.insurance_service.InsurancePolicyCreated.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.InsurancePolicyCreated} returns this
 */
proto.insurance_service.InsurancePolicyCreated.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int32 year = 15;
 * @return {number}
 */
proto.insurance_service.InsurancePolicyCreated.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.InsurancePolicyCreated} returns this
 */
proto.insurance_service.InsurancePolicyCreated.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 deductible = 16;
 * @return {number}
 */
proto.insurance_service.InsurancePolicyCreated.prototype.getDeductible = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.InsurancePolicyCreated} returns this
 */
proto.insurance_service.InsurancePolicyCreated.prototype.setDeductible = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string limits = 17;
 * @return {string}
 */
proto.insurance_service.InsurancePolicyCreated.prototype.getLimits = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.InsurancePolicyCreated} returns this
 */
proto.insurance_service.InsurancePolicyCreated.prototype.setLimits = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string policy_id = 18;
 * @return {string}
 */
proto.insurance_service.InsurancePolicyCreated.prototype.getPolicyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.InsurancePolicyCreated} returns this
 */
proto.insurance_service.InsurancePolicyCreated.prototype.setPolicyId = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string subscription_id = 19;
 * @return {string}
 */
proto.insurance_service.InsurancePolicyCreated.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.InsurancePolicyCreated} returns this
 */
proto.insurance_service.InsurancePolicyCreated.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional int32 deprecated_policy_id = 2;
 * @return {number}
 */
proto.insurance_service.InsurancePolicyCreated.prototype.getDeprecatedPolicyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.InsurancePolicyCreated} returns this
 */
proto.insurance_service.InsurancePolicyCreated.prototype.setDeprecatedPolicyId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string deprecated_policy_number = 3;
 * @return {string}
 */
proto.insurance_service.InsurancePolicyCreated.prototype.getDeprecatedPolicyNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.InsurancePolicyCreated} returns this
 */
proto.insurance_service.InsurancePolicyCreated.prototype.setDeprecatedPolicyNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 deprecated_effective_date = 4;
 * @return {number}
 */
proto.insurance_service.InsurancePolicyCreated.prototype.getDeprecatedEffectiveDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.InsurancePolicyCreated} returns this
 */
proto.insurance_service.InsurancePolicyCreated.prototype.setDeprecatedEffectiveDate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 deprecated_expiration_date = 5;
 * @return {number}
 */
proto.insurance_service.InsurancePolicyCreated.prototype.getDeprecatedExpirationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.InsurancePolicyCreated} returns this
 */
proto.insurance_service.InsurancePolicyCreated.prototype.setDeprecatedExpirationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.InsurancePolicyUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.InsurancePolicyUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.InsurancePolicyUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.InsurancePolicyUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    newDailyRateCents: jspb.Message.getFieldWithDefault(msg, 3, 0),
    newMonthlyRateCents: jspb.Message.getFieldWithDefault(msg, 4, 0),
    oldDailyRateCents: jspb.Message.getFieldWithDefault(msg, 5, 0),
    oldMonthlyRateCents: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.InsurancePolicyUpdated}
 */
proto.insurance_service.InsurancePolicyUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.InsurancePolicyUpdated;
  return proto.insurance_service.InsurancePolicyUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.InsurancePolicyUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.InsurancePolicyUpdated}
 */
proto.insurance_service.InsurancePolicyUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNewDailyRateCents(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNewMonthlyRateCents(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOldDailyRateCents(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOldMonthlyRateCents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.InsurancePolicyUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.InsurancePolicyUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.InsurancePolicyUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.InsurancePolicyUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNewDailyRateCents();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getNewMonthlyRateCents();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getOldDailyRateCents();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOldMonthlyRateCents();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.insurance_service.InsurancePolicyUpdated.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.InsurancePolicyUpdated} returns this
 */
proto.insurance_service.InsurancePolicyUpdated.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.insurance_service.InsurancePolicyUpdated.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.InsurancePolicyUpdated} returns this
 */
proto.insurance_service.InsurancePolicyUpdated.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 new_daily_rate_cents = 3;
 * @return {number}
 */
proto.insurance_service.InsurancePolicyUpdated.prototype.getNewDailyRateCents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.InsurancePolicyUpdated} returns this
 */
proto.insurance_service.InsurancePolicyUpdated.prototype.setNewDailyRateCents = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 new_monthly_rate_cents = 4;
 * @return {number}
 */
proto.insurance_service.InsurancePolicyUpdated.prototype.getNewMonthlyRateCents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.InsurancePolicyUpdated} returns this
 */
proto.insurance_service.InsurancePolicyUpdated.prototype.setNewMonthlyRateCents = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 old_daily_rate_cents = 5;
 * @return {number}
 */
proto.insurance_service.InsurancePolicyUpdated.prototype.getOldDailyRateCents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.InsurancePolicyUpdated} returns this
 */
proto.insurance_service.InsurancePolicyUpdated.prototype.setOldDailyRateCents = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 old_monthly_rate_cents = 6;
 * @return {number}
 */
proto.insurance_service.InsurancePolicyUpdated.prototype.getOldMonthlyRateCents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.InsurancePolicyUpdated} returns this
 */
proto.insurance_service.InsurancePolicyUpdated.prototype.setOldMonthlyRateCents = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.insurance_service.InsurancePolicyCancelled.prototype.toObject = function(opt_includeInstance) {
  return proto.insurance_service.InsurancePolicyCancelled.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.insurance_service.InsurancePolicyCancelled} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.InsurancePolicyCancelled.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endDate: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.insurance_service.InsurancePolicyCancelled}
 */
proto.insurance_service.InsurancePolicyCancelled.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.insurance_service.InsurancePolicyCancelled;
  return proto.insurance_service.InsurancePolicyCancelled.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.insurance_service.InsurancePolicyCancelled} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.insurance_service.InsurancePolicyCancelled}
 */
proto.insurance_service.InsurancePolicyCancelled.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.insurance_service.InsurancePolicyCancelled.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.insurance_service.InsurancePolicyCancelled.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.insurance_service.InsurancePolicyCancelled} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.insurance_service.InsurancePolicyCancelled.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndDate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.insurance_service.InsurancePolicyCancelled.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.InsurancePolicyCancelled} returns this
 */
proto.insurance_service.InsurancePolicyCancelled.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.insurance_service.InsurancePolicyCancelled.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.insurance_service.InsurancePolicyCancelled} returns this
 */
proto.insurance_service.InsurancePolicyCancelled.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 end_date = 3;
 * @return {number}
 */
proto.insurance_service.InsurancePolicyCancelled.prototype.getEndDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.insurance_service.InsurancePolicyCancelled} returns this
 */
proto.insurance_service.InsurancePolicyCancelled.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.insurance_service.RequestSource = {
  CUSTOMER: 0,
  CUSTOMER_SUPPORT: 1
};

goog.object.extend(exports, proto.insurance_service);
