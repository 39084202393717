import React from 'react'
import {
  Box,
  Card,
  Button,
  Divider,
  Tab,
  Tabs,
  Avatar,
  Typography,
} from '@mui/material'
import { Link, Switch, useLocation, Route } from 'react-router-dom'
import { CustomerContextProvider, useCustomerContext } from './CustomerContext'
import { CustomerInfo } from './CustomerInfo'
import { CustomerInvoices } from './Invoices/CustomerInvoicesComponent'
import { autonomyColor } from '../../global-styles/autonomyColor'
import { CQETab } from './CQE/CQETab'
import { ResetLoginAttemptsButton } from './ResetLoginAttempts/ResetLoginAttemptsButton'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import SubscriptionMileage from './Mileage/SubscriptionMileage'
import CustomerDocuments from './Documents/CustomerDocuments'

const CustomerDetailsTabsWrapper = () => {
  const { customerId, customerName, subscriptionId, recurringBillingDay } =
    useCustomerContext()
  //const { accountBalance } = useGetAccountBalance(customerId)
  const { pathname } = useLocation()
  const [open, setOpen] = React.useState(false)
  //const [continueChangeBillingDay, setContinueChangeBillingDay] = React.useState(false)
  const handleClickOpen = () => {
    //setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  //Payments two element that needs refactored
  //const balanceDetails = (
  //  <>
  //    <Typography color={autonomyColor.pink} variant="overline">
  //      Total balance
  //    </Typography>
  //    <Typography variant="h4">
  //      {formatMoney(accountBalance.openBalance)}
  //    </Typography>

  //    <Divider sx={{ my: 2, borderColor: autonomyColor.lightBlue }} />
  //    <Typography color={autonomyColor.pink} variant="overline">
  //      Past due balance
  //    </Typography>
  //    <Typography variant="h4">
  //      {formatMoney(accountBalance.pastDueBalance)}
  //    </Typography>
  //  </>
  //)
  function onClickChangeBillingDay() {
    //doDeleteMileagePackage(recurringBillableItems).then(()=>setHideDeleteMileageButton(true)).then(()=>refetchBillableItems())
    handleClose()
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        p: 3,
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: 'background.default',
          p: 3,
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Avatar
            src={''}
            sx={{
              height: 42,
              width: 42,
            }}
          />
          <Box sx={{ ml: 1 }}>
            <Typography
              sx={{ fontSize: 25 }}
              color="inherit"
              variant="subtitle2"
            >
              {customerName}
            </Typography>
            <Typography color={autonomyColor.white} variant="body2">
              user_id: {customerId}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex' }}>
          {recurringBillingDay != 0 && (
            <Box sx={{ ml: 1, marginRight: '60px' }} onClick={handleClickOpen}>
              <Typography color={autonomyColor.pink} variant="overline">
                Recurring Billing Day
              </Typography>
              <Typography variant="h4" align={'center'}>
                {recurringBillingDay}
              </Typography>
            </Box>
          )}
          {/* Payments 2 that needs refactored <Box>balanceDetails</Box>*/}
        </Box>
      </Box>
      <Card>
        <Tabs sx={{ px: 3 }} value={pathname}>
          <Tab
            component={Link}
            label={'Details'}
            value={`/customers/${customerId}`}
            to={`/customers/${customerId}`}
          />
          <Tab
            component={Link}
            label={'Invoices'}
            value={`/customers/${customerId}/invoices`}
            to={`/customers/${customerId}/invoices`}
          />
          <Tab
            component={Link}
            label={'CQE'}
            value={`/customers/${customerId}/cqe`}
            to={`/customers/${customerId}/cqe`}
          />
          <Tab
            component={Link}
            label={'Mileage'}
            value={`/customers/${customerId}/mileage`}
            to={`/customers/${customerId}/mileage`}
          />
          <Tab
            component={Link}
            label={'Documents'}
            value={`/customers/${customerId}/documents`}
            to={`/customers/${customerId}/documents`}
          />
        </Tabs>

        <Divider />
        <Switch>
          <Route path="/customers/:customerId" exact>
            <CustomerInfo />
          </Route>
          <Route path="/customers/:customerId/invoices">
            <CustomerInvoices />
          </Route>
          <Route path="/customers/:customerId/cqe">
            <CQETab />
          </Route>
          <Route path="/customers/:customerId/mileage">
            <SubscriptionMileage subscriptionId={subscriptionId} />
          </Route>
          <Route path="/customers/:customerId/documents">
            <CustomerDocuments />
          </Route>
        </Switch>
      </Card>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 3,
          marginBottom: 5,
        }}
      >
        <ResetLoginAttemptsButton />
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Change Recurring Billing Day'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will change the customers billing day to , are you sure you
            want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Stop</Button>
          <Button onClick={onClickChangeBillingDay} autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export const CustomerDetailsPage = () => {
  return (
    <CustomerContextProvider>
      <CustomerDetailsTabsWrapper />
    </CustomerContextProvider>
  )
}
