import {
  CustomerState,
  QualificationTier,
} from '@nxcr-org/web-api-interface/lib/domain_pb'
import { Customer } from '@nxcr-org/web-api-interface/lib/nexus_bff_pb'
import { Modifier } from '@nxcr-org/web-api-interface/lib/web_pb'
import { NexusService } from 'global-apis/nexus-service'
import startCase from 'lodash/startCase'
import { useIsFetching, useQuery } from 'react-query'
import { getKeyByValue } from 'scheduling/tasks/utils'
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
  BooleanParam,
} from 'use-query-params'

const mapRows = (row: Customer.AsObject) => {
  return {
    ...row,
    cqeStatus: getCQEStatus(row.cqeStatus),
    state: getCustomerStatus(row.state),
    vehicle: row.make ? `${row.make}, ${row.model}, ${row.trim}` : 'N/A',
    isDepositPaid: row.isDepositPaid ? 'Yes' : 'No',
    createdAt: new Date(row.createdAt).toLocaleDateString(),
  }
}

const getCQEStatus = (status: Customer.AsObject['cqeStatus']) => {
  const cqeStatusString = startCase(
    getKeyByValue(QualificationTier, status).toLowerCase()
  )

  switch (status) {
    case 2:
    case 3:
    case 4:
      return 'Auto Approved'
    default:
      return cqeStatusString
  }
}
const getCustomerStatus = (num) => {
  return Object.keys(CustomerState).find((key) => CustomerState[key] === num)
}
export function useCustomersTable() {
  const [
    { page, rowsPerPage, search, cqeStatus, state, blankNames },
    setQueryParams,
  ] = useQueryParams({
    search: StringParam,
    page: withDefault(NumberParam, 0),
    rowsPerPage: withDefault(NumberParam, 25),
    cqeStatus: withDefault(NumberParam, undefined),
    state: withDefault(NumberParam, undefined),
    blankNames: withDefault(BooleanParam, false),
  })

  const setPage = (nextPage: number) => {
    setQueryParams({ page: nextPage })
  }

  const setRowsPerPage = (nextRowsPerPage: number) => {
    setQueryParams({ rowsPerPage: nextRowsPerPage })
  }

  const setQuery = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams({ search: evt.target.value })
  }

  const setCQEStatus = (newCQEStatus: number) => {
    setQueryParams({ cqeStatus: newCQEStatus })
  }
  const setState = (newState: number) => {
    setQueryParams({ state: newState })
  }
  const setBlankNames = (newValue: boolean) => {
    setQueryParams({ blankNames: newValue })
  }
  const isFetching = useIsFetching()

  const { data: customers } = useQuery(
    ['/customers', { page, rowsPerPage, search, cqeStatus, state, blankNames }],
    async () => {
      const query = [
        {
          property: 'search',
          value: search,
          modifier: Modifier.EQUAL_TO,
        },
      ]
      if (cqeStatus !== undefined) {
        query.push({
          property: 'cqeStatus',
          value: `${cqeStatus}`,
          modifier: Modifier.EQUAL_TO,
        })
      }
      if (!blankNames) {
        query.push({
          property: 'customerName',
          // modifier: Modifier.CONTAINS,
          modifier: 13,
          value: '',
        })
      }
      if (state !== undefined) {
        query.push({
          property: 'state',
          value: `${state}`,
          modifier: 2,
        })
      }

      return NexusService.getBffCustomers({
        pagination: {
          limit: rowsPerPage,
          offset: page * rowsPerPage,
        },
        query,
        order: [
          {
            property: 'updatedAt',
            direction: 1,
          },
        ],
      }).then((res) => {
        return {
          total: res.total,
          customers: res.dataList.map(mapRows),
        }
      })
    },
    {
      placeholderData: {
        total: 0,
        customers: [],
      },
    }
  )

  return {
    total: customers?.total || 0,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    customers: customers?.customers || [],
    setQuery,
    setCQEStatus,
    search,
    cqeStatus,
    setBlankNames,
    state,
    setState,
    isLoading: isFetching > 0,
  }
}
